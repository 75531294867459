/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Tabs, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import FacilityDetails from './FacilityDetails';
import VendorDetails from './VendorDetails';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import JobCreateApiService from '../../Services/JobCreateService';
import {
  JobDetails,
  JobExecution,
  formatDate,
  getAuthData,
  getAuthDetails,
  getStoredCustomerDetails,
} from '../../../../Shared/Utilities/utils';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import {
  BidStatus,
  EntityType,
  Facility,
  JobStatus,
  JobSummaryV2,
  JobType,
  ServiceCategoryV3,
  WorkOrderStatus,
} from '../../../../API';
import { Loader, TabsWrapper } from '../JobView/JobsView.styles';
import { ErrorMessage } from '../../../../Shared/Components/Common/ErrorMessage/ErrorMessage';
import { FacilityAndVendorDetailsContainer, HeadingText, TextFieldWrapper } from './OneTimeJobDetails.Style';
import theme from '../../../../Shared/Themes/theme';
import BidDetails from './BidDetails';
import AwardJob from './AwardJob';
import { UserType, JobStatuses, SubmittedBidStatus, DateFormat } from '../../../../Shared/Constants/App';
import { TabSection, TabTitle } from '../JobDetailsOverView/JobDetailsOverview.styles';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { MRT_ColumnDef } from 'material-react-table';
import { IJobOverview } from '../JobsListView/JobsListView';
import { TaskData } from '../WorkTasks/WorkTasks';
import { IJobRow, IJobs } from '../../Models/JobsView.Model';
import { ActionsColumn, Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { Icons } from '../../../../Shared/Constants/Icons';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import DeleteJobs from '../DeleteJobDialog/DeleteJobDialog';
import AwardedBidDetails from './AwardedBidDetails';
import { isEmpty } from 'lodash';
import CreateBidServices from '../../../Vendor/Services/CreateBidServices';
import { Estimation, JobServices } from '../../../../Shared/Models/Bid';
import RatingSystem from '../RatingSystem/RatingSystem';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import BidRequestRevisionComments from '../../../Vendor/Components/BidRevision/BidRequestRevisionComments';
import VendorApiService from '../../../../Shared/Services/VendorService';
import Modal from '../../Utilities/Shared/Components/Modal';
import { Controller, useForm } from 'react-hook-form';
import { textField } from '../../../Vendor/Components/BidRevision/Popup.Styles';
import { Patterns } from '../../../../Shared/Constants';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { ErrorMsg } from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import { IConversationContext } from '../PostJobAwardConversations/PostJobAwardConversations';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import { IGraphQLResponse } from '../../../../Shared/Models';

interface IJobDetails {
  jobDetails: JobSummaryV2;
  facility: Facility;
  executionType: string;
  RejectedStatus: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface IBidList {
  bidName: string;
  bidId: string;
  vendorId: string;
  bidVersion: string | number;
  bidEstimationType: string;
  draftStep?: number;
  approvedByCustomer?: string | number;
  totalTasks?: number;
  jobId?: string;
  executionType?: string;
  customerId?: string;
  dataStatus?: string;
  finalQuote?: number;
}

export interface IBidItem {
  bidId: string;
  vendorId: string;
  isFlatMargin: boolean;
  profitMargin: number;
}

export interface IJobServiceItem {
  mdServiceId: string;
}
export interface IJobItem {
  jobName: string;
  jobStatus: JobStatuses;
  jobId: string;
  customerId: string;
  facilityType: string;
  subJobServices: IJobServiceItem[];
  jobBids: IBidItem[];
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps & { style?: React.CSSProperties }) {
  const { children, value, index, style, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}>
      {value === index && children}
    </div>
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const OneTimeJobDetails = () => {
  const { t } = useTranslation(['dashboard', 'ratingSystem', 'conversations']);
  const [jobDetails, setJobDetails] = useState<IJobDetails>({
    jobDetails: {} as JobSummaryV2,
    facility: {} as Facility,
    executionType: '',
    RejectedStatus: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setIsError] = useState<boolean>(false);
  const [bidsList, setBidsList] = useState<IBidList[]>([]);
  const [dialogueBoxOpen, setDialogueBoxOpen] = useState(false);
  const [workOrderStatus, setWorkOrderStatus] = useState<WorkOrderStatus>();

  const location = useLocation();
  const jobId = location.state?.job?.jobId;

  const urlId = location.search.split('?id=')[1];
  let parsedJob: any;

  const [tabSelected, setTabSelected] = useState(location?.pathname.includes('onetimejobdetails/bids') ? 1 : 0);
  const [finalQuote, setFinalQuote] = useState(0);
  const [isServicesLoading, setIsServicesLoading] = useState<boolean>(false);
  const [services, setServices] = useState<any[]>([]);
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState<boolean>(false);

  const jobStatus = location.state?.job?.jobStatus ?? location.state?.job?.status; //'Draft';
  const { executionType } = location?.state?.job ?? {};

  const { role, isDistrictManager } = getAuthData();
  const navigate = useNavigate();
  const [ratingValue, setRatingValue] = useState<number | null>(
    jobStatus === JobStatus.Completed
      ? location.state.job?.workOrderRating
        ? location.state.job?.workOrderRating
        : location.state.job?.jobRating
          ? location.state.job?.jobRating
          : null
      : null
  );
  const [isUpdateRatingLoading, setIsUpdateRatingLoading] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(
    location?.state?.job?.workOrderRating || location?.state?.job?.jobRating ? true : false
  );
  const [ratingTitle, setRatingTitle] = useState<string>(
    location?.state?.job?.workOrderRating || location?.state?.job?.jobRating
      ? t('ratingSystem:rating')
      : t('ratingSystem:rateTheJob')
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [customerComment, setCustomerComment] = useState<string>('');
  const [updatedJobName, setUpdatedJobName] = useState<string>('');
  const [jobNameLoader, setJobNameLoader] = useState(false);
  const [charactersLeft, setCharactersLeft] = useState(50);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const [conversationContext, setConversationContext] = useState<IConversationContext>({} as IConversationContext);

  const { handleSubmit, control, setValue, setError } = useForm({
    defaultValues: {
      jobName: updatedJobName,
    },
  });

  const storedAuthDetails = getAuthDetails();

  const loggedInUserInternalId = storedAuthDetails['custom:UserId'];
  const loggedInUserName = storedAuthDetails['name'];

  const isOtjJob = useMemo(() => {
    return (
      location?.state?.facilityItem?.jobCustodian &&
      (location?.state?.facilityItem?.jobType === JobType.OneTime ||
        location?.state?.facilityItem?.jobType === JobType.Emergency)
    );
  }, [location?.state?.facilityItem?.jobCustodian, location?.state?.facilityItem?.jobType]);

  const isSubVendorInSidePeazy = useMemo(() => {
    return (
      isOtjJob &&
      location?.state?.facilityItem?.executionType === JobDetails.SubVendor &&
      location?.state?.facilityItem?.estimationType === JobExecution.EstimateWithinPeazy
    );
  }, [isOtjJob, location?.state?.facilityItem?.executionType, location?.state?.facilityItem?.estimationType]);

  const fetchJobDetails = async (jobId: string) => {
    setIsLoading(true);
    setJobNameLoader(true);
    try {
      let customerId = getCustomerId();

      const jobSummaryResponse = await fetchJobSummary(customerId as string, jobId);
      if (jobSummaryResponse?.data?.customerId) {
        customerId = jobSummaryResponse.data.customerId;
      }
      setUpdatedJobName(jobSummaryResponse?.data?.jobName as SetStateAction<string>);

      const isOtjJob = !!jobSummaryResponse?.data?.jobCustodian;

      const mExecutionType =
        isOtjJob &&
        (jobSummaryResponse?.data?.jobVendors[0]?.mdVendorType === JobExecution.All
          ? JobExecution.SubVendor
          : jobSummaryResponse?.data?.jobVendors[0]?.mdVendorType === JobExecution.SubVendor
            ? JobExecution.SubVendor
            : JobExecution.Self);
      const mEstimationType =
        isOtjJob &&
        (jobSummaryResponse?.data?.jobVendors[0]?.mdVendorType === JobDetails.All
          ? JobDetails.EstimateWithinPeazy
          : JobDetails.EstimateOutsidePeazy);

      if (jobSummaryResponse.data) {
        const facilityResponse = await fetchFacility(
          jobSummaryResponse.data.jobFacilities[0].facilityId as string,
          customerId as string
        );
        setJobDetails({
          jobDetails: { ...jobSummaryResponse.data, executionType: mExecutionType, estimationType: mEstimationType },
          facility: facilityResponse.data,
          executionType,
          RejectedStatus: location?.state?.job?.Rejected ?? '',
        });
      }

      setIsError(false);
    } catch (error) {
      console.error('Error fetching job details:', error);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setJobNameLoader(false);
    }
  };

  const getCustomerId = () => {
    if (location?.state?.job && role === UserType.Vendor) {
      return getStoredCustomerDetails()?.customerId || '';
    } else if (parsedJob) {
      location.state = parsedJob;
    }
    return getStoredCustomerDetails()?.customerId || '';
  };

  const fetchJobSummary = async (customerId: string, jobId: string) => {
    return await JobCreateApiService.jobSummaryV2(customerId, jobId);
  };

  const fetchFacility = async (facilityId: string, customerId: string) => {
    return await FacilityApiService.facility(facilityId, customerId);
  };

  const getBidSubmissionsData = async () => {
    let jobId;
    if (role == UserType.Vendor) {
      jobId = location.state?.job?.subJobId;
    } else {
      if (parsedJob) {
        location.state = parsedJob;
      }
      jobId = location.state.job.jobId;
    }
    setIsLoading(true);
    const jobBidsResponse =
      role === UserType.Customer
        ? await JobCreateApiService.getJobBids(jobId as string)
        : await JobCreateApiService.allBidSubmissionsForJobId(jobId as string);
    if (jobBidsResponse?.errors?.length === 0) {
      setBidsList(jobBidsResponse.data);
    }
    setIsLoading(false);
  };

  const handleChangeTabs = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
    if (newValue === 0) {
      navigate(`/onetimejobdetails?id=${urlId}`, { state: { ...location.state } });
    } else {
      navigate(`/onetimejobdetails/bids?id=${urlId}`, { state: { ...location.state } });
    }
    if (newValue === 1) {
      getBidSubmissionsData();
    } else {
      fetchJobDetails(location.search.split('?id=')[1]);
    }
  }, []);

  const getServiceCategories = async (customerId: string) => {
    try {
      const masterData = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      return masterData.data ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const fetchBidAwardDetails = useCallback(async () => {
    const jobBidsResponse = await JobCreateApiService.getJobBids(jobId as string);

    if (jobBidsResponse?.data && isEmpty(jobBidsResponse?.errors)) {
      const filteredData = jobBidsResponse?.data?.filter((item: { dataStatus: string }) => item.dataStatus === 'C');
      if (!isEmpty(filteredData)) {
        fetchAllServicesLinkedToBid(
          jobId as string,
          filteredData?.[0]?.vendorBidId as string,
          filteredData?.[0]?.jobEstimations
        );
        setFinalQuote(filteredData?.[0]?.totalCost as SetStateAction<number>);
      }
    }
  }, []);

  const calculateUniqueEstimations = (allServicesResponse: any) => {
    const uniqueEstimationsMap: Record<string, { totalCost: number; maxMdMeasureType: number; maxQuantity: number }> =
      {};

    allServicesResponse?.data?.forEach(
      (estimation: { mdCategoryId: any; totalCost: number; mdMeasureType: number; quantity: number }) => {
        const mdCategoryId = estimation.mdCategoryId;
        const totalCost = estimation.totalCost || 0;
        const mdMeasureType = estimation.mdMeasureType || 0;
        const quantity = estimation.quantity || 0;

        if (!uniqueEstimationsMap[mdCategoryId]) {
          uniqueEstimationsMap[mdCategoryId] = {
            totalCost,
            maxMdMeasureType: mdMeasureType,
            maxQuantity: quantity,
          };
        } else {
          uniqueEstimationsMap[mdCategoryId].totalCost += totalCost;

          if (mdMeasureType > uniqueEstimationsMap[mdCategoryId].maxMdMeasureType) {
            uniqueEstimationsMap[mdCategoryId].maxMdMeasureType = mdMeasureType;
          }

          if (quantity > uniqueEstimationsMap[mdCategoryId].maxQuantity) {
            uniqueEstimationsMap[mdCategoryId].maxQuantity = quantity;
          }
        }
      }
    );

    const uniqueEstimationsArray: Estimation[] = Object.keys(uniqueEstimationsMap).map((mdCategoryId) => ({
      mdCategoryId,
      totalCost: uniqueEstimationsMap[mdCategoryId].totalCost,
      mdMeasureType: uniqueEstimationsMap[mdCategoryId].maxMdMeasureType,
      quantity: uniqueEstimationsMap[mdCategoryId].maxQuantity,
    }));
    return uniqueEstimationsArray;
  };

  const fetchAllServicesLinkedToBid = useCallback(async (jobId: string, bidId: string, jobEstimations: any) => {
    setIsServicesLoading(true);
    const { customerId = '' } = getStoredCustomerDetails() || {};

    const allServicesResponse = await CreateBidServices.allJobBidEstimation(jobId, bidId);

    const response = calculateUniqueEstimations(allServicesResponse);

    const serviceCategories = await getServiceCategories(customerId as string);

    const jobServices: JobServices[] = (response || []).map((bidEstimationItem: any, index: number) => {
      const selectedService: any = serviceCategories.find(
        (serviceCategoryItem: ServiceCategoryV3) =>
          serviceCategoryItem?.serviceCategoryId === bidEstimationItem?.mdCategoryId
      );
      return {
        service: selectedService?.serviceCategoryName ?? '',
        id: index + 1,
        mdServiceId: bidEstimationItem?.mdCategoryId,
        totalJobEstimation: bidEstimationItem?.totalCost,
        serviceMeasure: bidEstimationItem?.mdMeasureType,
      };
    });

    const updatedJobServices = jobServices.map((service) => {
      const matchedEstimation: any = jobEstimations?.find(
        (estimation: { mdCategoryId: any }) => estimation.mdCategoryId === service.mdServiceId
      );
      if (matchedEstimation) {
        return {
          ...service,
          totalJobEstimation: matchedEstimation?.quoteAmount,
        };
      }
      return service;
    });
    setServices(updatedJobServices);
    setIsServicesLoading(false);
  }, []);

  useEffect(() => {
    if (role == UserType.Customer) {
      fetchBidAwardDetails();
    }
  }, []);

  const renderCell = (renderedCellValue: React.ReactNode) => {
    // If renderedCellValue is a string, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue?.toFixed(2) : '-';
    }
    return '-';
  };

  const handleClickOpen = () => {
    setDialogueBoxOpen(true);
  };

  const formatDateCell = (cell: any) => {
    const cellValue = cell.getValue();
    if (cellValue) {
      return formatDate(cellValue, DateFormat.StandardDate);
    }
    return '-';
  };

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'vendorName',
        header: t('tableHeader:vendorName'),
        enableSorting: true,
        enableColumnFilter: true,
        muiEditTextFieldProps: {
          required: true,
          type: 'number',
          variant: 'outlined',
        },
        size: 210,
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'bidName',
        header: t('tableHeader:bidName'),
        enableSorting: true,
        enableColumnFilter: true,
        muiEditTextFieldProps: {
          required: true,
          type: 'number',
          variant: 'outlined',
        },
        size: 210,
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'bidSubmissionDate',
        header: t('tableHeader:bidSubmissionDate'),
        enableSorting: true,
        enableColumnFilter: true,
        size: 178,
        filterVariant: 'date',
        filterFn: (row, _columnIds, filterValue) => {
          const formattedValue = formatDate(row.getValue('jobStartDate'), DateFormat.StandardDate);
          const filteredValue = formatDate(filterValue.$d, DateFormat.StandardDate);
          return formattedValue.includes(filteredValue);
        },
        Cell: ({ cell }) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'totalCost',
        header: t('tableHeader:finalQuote'),
        enableSorting: true,
        enableColumnFilter: false,
        size: 178,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: true,
      },
      // {
      //   accessorKey: 'comments',
      //   header: t('tableHeader:comments'),
      //   enableSorting: true,
      //   enableColumnFilter: true,
      //   size: 234,
      //   Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      //   enableColumnActions: false,
      //   sortingFn: 'alphanumericCaseSensitive',
      // },
      {
        header: t('tableHeader:actions'),
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderActionsCell(row),
      },
    ],
    []
  );

  const renderActionsCell = (row: any) => {
    const { jobId } = row.original as unknown as IJobRow;
    const { customerId } = getStoredCustomerDetails();
    const jobData = location.state?.job;
    return (
      <ActionsColumn>
        <Linking
          to={{ pathname: `/create-bid` }}
          state={{
            jobDetails: {
              ...jobData,
              jobEstimations: row.original.jobEstimations,
              bidId: row.original.vendorBidId, //vendorBidId,
              vendorName: row.original.vendorName,
              vendorId: row.original.vendorId,
              serviceSchedule: 'OneTime',
            },
            finalQuote: row.original.totalCost,
            jobId,
            customerId,
            isJobCreation: false,
          }}
          key={0}>
          <WhiteTooltip title={t('homePage:viewDetails')}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <img src={Icons.EyeIcon} alt={t('altTexts:modify')} />
            </IconButton>
          </WhiteTooltip>
        </Linking>
        <Linking
          to={{ pathname: `/conversations`, search: `?jobId=${location.state?.job?.jobId}` }}
          state={{
            ...jobData,
            ...row.original,
            bidId: row.original.vendorBidId,
            vendorName: row.original.vendorName,
            vendorId: row.original.vendorId,
            serviceSchedule: 'OneTime',
            finalQuote: row.original.totalCost,
            jobId,
            customerId,
            isJobCreation: false,
            tag: 'BID_RELATED',
            FromBidsTab: true,
          }}
          key={0}>
          <WhiteTooltip title={t('vendor:message')}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <img
                src={Icons.ConversationIcon}
                alt={t('altTexts:conversation')}
                style={{ width: '1.3rem', marginTop: '2px' }}
              />
            </IconButton>
          </WhiteTooltip>
        </Linking>
      </ActionsColumn>
    );
  };

  useEffect(() => {
    localStorage.removeItem('currentJob');
    const locationState = localStorage.getItem('locationState');
    if (locationState) {
      parsedJob = JSON.parse(locationState ?? '');
    }
    if (role == UserType.Vendor) {
      getBidSubmissionsData();
    }
    getWorkOrderDetails();
  }, []);

  useEffect(() => {
    if (tabSelected === 1) {
      getBidSubmissionsData();
    } else {
      fetchJobDetails(location.search.split('?id=')[1]);
    }
  }, [tabSelected]);

  useEffect(() => {
    if (window.location.pathname == '/onetimejobdetails') {
      setTabSelected(0);
    } else if (window.location.pathname.includes('/onetimejobdetails/bids')) {
      setTabSelected(1);
    }
  }, [window.location.pathname]);

  const getWorkOrderDetails = async () => {
    const workorderResponse = await WorkOrdersApiService.getAllWorkOrders({
      jobId: location?.state?.facilityItem?.jobId,
    });
    if (workorderResponse.data && workorderResponse?.errors?.length === 0) {
      setWorkOrderStatus(workorderResponse.data[0]?.status);
    }
  };

  const showConversationIcon = () => {
    const { customerId } = getStoredCustomerDetails();
    return (
      tabSelected === 0 &&
      location?.state?.job?.dataStatus !== JobStatuses.Draft &&
      (!location?.state?.job?.jobCustodian ||
        (location?.state?.job?.jobCustodian &&
          (customerId !== location?.state?.job?.jobCustodian ||
            (customerId === location?.state?.job?.jobCustodian &&
              location?.state?.job?.executionType === 'subVendor' &&
              location?.state?.job?.estimationType === 'estimateWithinPeazy'))))
    );
  };

  const showConversationTitle = useCallback((): string => {
    if (role === UserType.Customer) {
      return t('conversations:chatWithVendor');
    } else if (role === UserType.Vendor) {
      if (isDistrictManager) {
        return isSubVendorInSidePeazy ? t('conversations:chatWithSubVendors') : t('conversations:chatWithCustomer');
      } else {
        return isSubVendorInSidePeazy ? t('conversations:chatWithDM') : t('conversations:chatWithCustomer');
      }
    }
    return '';
  }, []);

  const handleConversationIconClick = useCallback(() => {
    if (
      (location?.state?.job?.dataStatus || location?.state?.job?.jobDataStatus) &&
      location?.state?.job?.dataStatus !== JobStatuses.Awarded &&
      location?.state?.job?.jobDataStatus !== JobStatuses.Awarded &&
      location?.state?.job?.dataStatus !== JobStatuses.InProgress &&
      location?.state?.job?.jobDataStatus !== JobStatuses.InProgress
    ) {
      const queryParams = new URLSearchParams({
        jobId: location.state?.job?.jobId,
      });
      navigate(`/conversations?${queryParams?.toString()}`, { state: { ...location.state?.facilityItem } });
    } else {
      const queryParams = new URLSearchParams({
        jobId: location.state?.job?.jobId,
      });

      navigate(`/post-job-award-conversations?${queryParams?.toString()}`, {
        state: {
          ...location.state?.facilityItem,
          conversationContext: conversationContext,
        },
      });
    }
  }, [conversationContext]);

  const handleCustomerUserFlow = useCallback(async (customerId: string) => {
    const jobSummaryResponse = await fetchJobSummary(customerId, jobId);
    if (
      role === UserType.Customer &&
      !isEmpty(jobSummaryResponse) &&
      (jobSummaryResponse?.data?.dataStatus === JobStatus.Awarded ||
        jobSummaryResponse?.data?.dataStatus === JobStatus.Completed ||
        jobSummaryResponse?.data?.dataStatus === JobStatus.InProgress)
    ) {
      const jobBidsResponse = await JobCreateApiService.getJobBids(jobId);

      const jobBids = jobBidsResponse?.data?.map((bid: { dataStatus: any; vendorId: any; vendorName: any }) => ({
        dataStatus: bid?.dataStatus,
        vendorId: bid?.vendorId,
        vendorName: bid?.vendorName,
      }));

      const vendorUserName = await OrganizationService.getUserNames({
        jobId: location?.state?.facilityItem?.jobId ?? '',
      });
      const vendorId = location.state?.facilityItem?.vendorId;
      const awardedBids =
        jobSummaryResponse?.data?.dataStatus === JobStatus.Completed
          ? {
              vendorId,
              vendorUserName: vendorUserName?.data?.[0]?.vendorUserName ?? '',
              vendorName: vendorUserName?.data?.[0]?.vendorName ?? '',
            }
          : jobBids?.find((item: { dataStatus: BidStatus }) => item?.dataStatus === BidStatus.C);

      handleCustomerDefaultFlow(vendorUserName, awardedBids, jobSummaryResponse);
    }
  }, []);

  const handleCustomerDefaultFlow = useCallback(
    (vendorUserName: IGraphQLResponse, awardedBids: any, jobSummaryResponse: IGraphQLResponse) => {
      setConversationContext((prevConversationContextForCustomerOrAssociate) => {
        return {
          ...prevConversationContextForCustomerOrAssociate,
          userId1: vendorUserName?.data?.[0]?.vendorUserId ?? '',
          userId1Name: awardedBids?.vendorName ?? '',
          userId2: loggedInUserInternalId ?? '',
          userId2Name: jobSummaryResponse?.data?.customerName ?? '',
          userId2UserName: loggedInUserName ?? '',
          userId1UserName: vendorUserName?.data?.[0]?.vendorUserName ?? '',
          jobId: jobSummaryResponse?.data?.jobId ?? '',
          userType: UserType.Customer,

          userAndJobDetails: {
            jobName: jobSummaryResponse?.data?.jobName ?? '',
            vendorId: vendorUserName?.data?.[0]?.vendorUserId ?? '',
            jobId: jobSummaryResponse?.data?.jobId ?? '',
            vendorUserName: vendorUserName?.data?.[0]?.vendorUserName ?? '',
            vendorCompanyName: awardedBids?.vendorName ?? '',
            userType: UserType.Customer,
            facilityName: '',
            conversationGroupName: '',
            associateId: '',
            associateName: '',
            customerOrDmSubVendorId: loggedInUserInternalId ?? '',
            customerOrDmOrSubVendorUserName: loggedInUserName ?? '',
            customerOrDmOrSubVendorCompanyName: jobSummaryResponse?.data?.customerName ?? '',
          },
          primaryContactName: t('common:chatWithVendor'),
        };
      });
    },
    []
  );

  const handleVendorUserFlow = useCallback(
    async ({
      response,
      isSubVendorOutSidePeazy,
      isSubVendorInSidePeazy,
      isSubVendorPerformingActionForRegularJobs,
      getUserNameForCustomerAndDm,
      primeVendorForRecurringJobs,
    }: {
      response: any;
      isSubVendorOutSidePeazy: boolean;
      isSubVendorInSidePeazy: boolean;
      isSubVendorPerformingActionForRegularJobs: boolean;
      getUserNameForCustomerAndDm: IGraphQLResponse;
      primeVendorForRecurringJobs: boolean;
    }) => {
      if (
        (!isDistrictManager && (isSubVendorOutSidePeazy || isSubVendorInSidePeazy)) ||
        isSubVendorPerformingActionForRegularJobs
      ) {
        handleSubVendorFlow(response, getUserNameForCustomerAndDm, isSubVendorOutSidePeazy);
      } else if (
        isDistrictManager &&
        (isSubVendorOutSidePeazy || isSubVendorInSidePeazy || primeVendorForRecurringJobs)
      ) {
        await handleDistrictManagerFlow(response, isSubVendorOutSidePeazy);
      } else {
        if (location.state?.facilityItem?.isSelfPerformJob) return;

        const customerDetailsResponse = await CustomerApiService.getCustomerDetailsResponse(
          location.state?.facilityItem?.customerId as string
        );

        handleVendorDefaultFlow(response, getUserNameForCustomerAndDm, customerDetailsResponse);
      }
    },
    []
  );

  const handleSubVendorFlow = useCallback(
    (response: any, getUserNameForCustomerAndDm: IGraphQLResponse, isSubVendorOutSidePeazy: boolean) => {
      if (isSubVendorOutSidePeazy) {
        return;
      }
      setConversationContext((prevConversationContext) => {
        return {
          ...prevConversationContext,
          userType: UserType.Vendor,
          userId1: loggedInUserInternalId ?? '',
          userId1Name: response?.data?.doingBusinessAs ?? '',
          userId1UserName: loggedInUserName ?? '',
          userId2: getUserNameForCustomerAndDm?.data?.[0]?.vendorUserId ?? '',
          userId2Name: getUserNameForCustomerAndDm?.data?.[0]?.vendorName ?? '',
          userId2UserName: getUserNameForCustomerAndDm?.data?.[0]?.vendorUserName ?? '',
          jobId: location?.state?.facilityItem?.jobId,

          userAndJobDetails: {
            jobName: location?.state?.facilityItem?.jobName ?? '',
            vendorId: loggedInUserInternalId ?? '',
            jobId: location?.state?.facilityItem?.jobId,
            vendorUserName: loggedInUserName ?? '',
            vendorCompanyName: response?.data?.doingBusinessAs ?? '',
            userType: UserType.Vendor,
            facilityName: '',
            conversationGroupName: '',
            associateId: '',
            associateName: '',
            customerOrDmSubVendorId: getUserNameForCustomerAndDm?.data?.[0]?.vendorUserId ?? '',
            customerOrDmOrSubVendorUserName: getUserNameForCustomerAndDm?.data?.[0]?.vendorUserName ?? '',
            customerOrDmOrSubVendorCompanyName: getUserNameForCustomerAndDm?.data?.[0]?.vendorName ?? '',
          },
          primaryContactName: t('common:chatWithDM'),
        };
      });
    },
    []
  );

  const handleDistrictManagerFlow = useCallback(async (response: any, isSubVendorOutSidePeazy: boolean) => {
    if (isSubVendorOutSidePeazy) {
      return;
    }

    const getUserNameForCustomerAndDm = await OrganizationService.getUserNames({
      jobId: location.state?.facilityItem?.subJobs?.[0]?.jobId ?? '',
    });

    setConversationContext((prevConversationContext) => {
      return {
        ...prevConversationContext,
        userType: UserType.Vendor,
        userId1: loggedInUserInternalId ?? '',
        userId1Name: response?.data?.doingBusinessAs ?? '',
        userId1UserName: loggedInUserName ?? '',
        userId2: getUserNameForCustomerAndDm?.data?.[0]?.subVendorUserId ?? '',
        userId2Name: getUserNameForCustomerAndDm?.data?.[0]?.subVendorName ?? '',
        userId2UserName: getUserNameForCustomerAndDm?.data?.[0]?.subVendorUserName ?? '',
        jobId: location.state?.facilityItem?.subJobs?.[0]?.jobId,

        userAndJobDetails: {
          jobName: location?.state?.facilityItem?.jobName ?? '',
          vendorId: loggedInUserInternalId ?? '',
          jobId: location.state?.facilityItem?.subJobs?.[0]?.jobId,
          vendorUserName: loggedInUserName ?? '',
          vendorCompanyName: response?.data?.doingBusinessAs ?? '',
          userType: UserType.Vendor,
          facilityName: '',
          conversationGroupName: '',
          associateId: '',
          associateName: '',
          customerOrDmSubVendorId: getUserNameForCustomerAndDm?.data?.[0]?.subVendorUserId ?? '',
          customerOrDmOrSubVendorUserName: getUserNameForCustomerAndDm?.data?.[0]?.subVendorUserName ?? '',
          customerOrDmOrSubVendorCompanyName: getUserNameForCustomerAndDm?.data?.[0]?.subVendorName ?? '',
        },
        primaryContactName: t('common:chatWithSubVendor'),
      };
    });
  }, []);

  const handleVendorDefaultFlow = useCallback(
    (response: any, getUserNameForCustomerAndDm: IGraphQLResponse, customerDetailsResponse: IGraphQLResponse) => {
      setConversationContext((prevConversationContext) => {
        return {
          ...prevConversationContext,
          userType: UserType.Vendor,
          userId1: loggedInUserInternalId ?? '',
          userId1Name: response?.data?.doingBusinessAs ?? '',
          userId1UserName: loggedInUserName ?? '',
          userId2: getUserNameForCustomerAndDm?.data?.[0]?.jobCreatorUserId ?? '',
          userId2Name: customerDetailsResponse?.data?.customer?.doingBusinessAs ?? '',
          userId2UserName: getUserNameForCustomerAndDm?.data?.[0]?.jobCreatorUserName ?? '',
          jobId: location.state?.facilityItem?.jobId ?? '',

          userAndJobDetails: {
            jobName: location.state?.facilityItem?.jobName ?? '',
            vendorId: loggedInUserInternalId ?? '',
            jobId: location.state?.facilityItem?.jobId ?? '',
            vendorUserName: loggedInUserName ?? '',
            vendorCompanyName: response?.data?.doingBusinessAs ?? '',
            userType: UserType.Vendor,
            facilityName: '',
            conversationGroupName: '',
            associateId: '',
            associateName: '',
            customerOrDmSubVendorId: getUserNameForCustomerAndDm?.data?.[0]?.jobCreatorUserId ?? '',
            customerOrDmOrSubVendorUserName: getUserNameForCustomerAndDm?.data?.[0]?.jobCreatorUserName ?? '',
            customerOrDmOrSubVendorCompanyName: customerDetailsResponse?.data?.customer?.doingBusinessAs ?? '',
          },
          primaryContactName: t('common:chatWithCustomer'),
        };
      });
    },
    []
  );

  const setDataForChat = useCallback(async () => {
    try {
      const customerId = getCustomerId();
      const getUserNameForCustomerAndDm = await OrganizationService.getUserNames({ jobId: jobId });
      const isSubVendorPerformingActionForRegularJobs =
        location.state?.facilityItem?.jobType === JobType.Recurring &&
        !isEmpty(location.state?.facilityItem?.parentWorkOrderId ?? '') &&
        !isEmpty(location.state?.facilityItem?.jobCustodian);

      const primeVendorForRecurringJobs =
        location.state?.facilityItem?.jobType === JobType.Recurring &&
        isEmpty(location.state?.facilityItem?.parentWorkOrderId ?? '') &&
        !isEmpty(location.state?.facilityItem?.jobCustodian);

      const isOTJFlow =
        !isEmpty(location.state?.facilityItem?.jobCustodian) &&
        (location.state?.facilityItem?.jobType === JobType.OneTime ||
          location.state?.facilityItem?.jobType === JobType.Emergency);

      const isSubVendorInSidePeazy =
        isOTJFlow &&
        location?.state?.facilityItem?.executionType === JobDetails.SubVendor &&
        location?.state?.facilityItem?.estimationType === JobExecution.EstimateWithinPeazy; // OTJ 2nd flow

      const isSubVendorOutSidePeazy =
        isOTJFlow &&
        location?.state?.facilityItem?.executionType === JobDetails.SubVendor &&
        location?.state?.facilityItem?.estimationType === JobExecution.EstimateOutsidePeazy; // For 3rd flow

      const response = await VendorApiService.getVendorDetails(customerId);
      if (role === UserType.Vendor) {
        await handleVendorUserFlow({
          response,
          isSubVendorOutSidePeazy,
          isSubVendorInSidePeazy,
          isSubVendorPerformingActionForRegularJobs,
          getUserNameForCustomerAndDm,
          primeVendorForRecurringJobs,
        });
      } else {
        await handleCustomerUserFlow(customerId);
      }
    } catch (error) {
      console.error('Error fetching vendor details or setting conversation context:', error);
    }
  }, []);

  const handleEditJobNameIconClick = () => {
    setValue(FieldNames.jobName, updatedJobName);
    setIsEditNameModalOpen(true);
  };

  const handleEditJobNameClose = () => {
    setIsEditNameModalOpen(!isEditNameModalOpen);
  };

  const renderEditNameHeaderContent = () => {
    return <HeadingText>{t('oneTimeJob:changeJobName')}</HeadingText>;
  };

  const renderEditNameBodyContent = () => {
    return (
      <>
        <TextFieldWrapper>
          <Controller
            control={control}
            name={FieldNames.jobName}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <TextField
                  id="outlined-required"
                  sx={{ ...textField, marginTop: '1.5em' }}
                  label={
                    <span>
                      {t('oneTimeJob:jobName')}
                      <span className="mandatory-field">*</span>
                    </span>
                  }
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      onChange(e.target.value);
                      setCharactersLeft(50 - e.target.value.length);
                    }
                  }}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => {
                    setIsInputFocused(false);
                    setError(FieldNames.jobName, { type: 'validate', message: '' });
                  }}
                  value={value}
                />
                {!(charactersLeft === 50 || charactersLeft === 0) && isInputFocused && (
                  <div
                    style={{
                      color: theme.palette.secondary.contrastText,
                      fontSize: theme.typography.h5?.fontSize,
                    }}>
                    {charactersLeft} {t('oneTimeJob:charactersLeft')}
                  </div>
                )}
                <div>
                  <ErrorMsg sx={{ paddingLeft: 0 }}>{error && error?.message}</ErrorMsg>
                </div>
              </>
            )}
            rules={{
              required: 'Job name is required',
              pattern: {
                value: Patterns.TitleWithDigitsAndSpecialCharacters,
                message: `${t('oneTimeJob:invalidJobName')}`,
              },
            }}
          />
        </TextFieldWrapper>
      </>
    );
  };

  const handleChangeName = async (jobName: any) => {
    setIsLoading(true);
    const { customerId = '' } = getStoredCustomerDetails();

    try {
      const updateJobResponse = await JobCreateApiService.updateJob(customerId as string, jobId as string, jobName);
      if (!isEmpty(updateJobResponse.errors)) {
        return handleApiError();
      }
      setUpdatedJobName(jobName?.jobName as SetStateAction<string>);
      fetchJobDetails(jobId as string);
      setIsEditNameModalOpen(false);
    } catch (error) {
      handleApiError();
    } finally {
      setIsLoading(false);
    }
  };
  const handleApiError = () => {
    setSnackbarMessage(t('message:errorWhileUpdatingJobName'));
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
    setIsLoading(false);
    return false;
  };

  const handleStarRating = async (value: number | null) => {
    setIsUpdateRatingLoading(true);
    try {
      if (value) {
        const updateWorkOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus({
          workOrderId: location.state.job?.workOrderId,
          workOrderRating: value,
        });
        if (updateWorkOrderResponse.data && isEmpty(updateWorkOrderResponse.errors)) {
          setRatingValue(value);
          setReadOnly(true);
          setRatingTitle(t('ratingSystem:rating'));
          setSnackbarMessage(t('ratingSystem:ratingSuccessMsg'));
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else if (!isEmpty(updateWorkOrderResponse.errors)) {
          setSnackbarMessage(updateWorkOrderResponse?.errors[0]?.message as SetStateAction<string>);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }
    } catch (error) {
      console.log('Error occured while updating the rating: ', error);
    }
    setIsUpdateRatingLoading(false);
  };

  const fetchBidDetails = async () => {
    const bidId = location?.state?.facilityItem?.bidId;
    const bidSummaryResponse = await VendorApiService.getBidSummary(bidId as string);
    const customerLatestComment = bidSummaryResponse.data?.bidComments?.filter(
      (comment: { entityType: any }) => comment?.entityType === EntityType.CUSTOMER || EntityType.VENDOR
    );
    setCustomerComment(customerLatestComment?.[0]?.comment as SetStateAction<string>);
  };

  useEffect(() => {
    if (location?.state?.isRequestRevision) {
      fetchBidDetails();
    }
    setDataForChat();
  }, []);

  return (
    <>
      <PageTitle
        title={updatedJobName}
        Loader={jobNameLoader}
        showJobNameEditIcon={jobStatus === JobStatuses.OpenToBid && location?.state?.isModifyJobDetails}
        showEditIcon={
          jobStatus === JobStatus.Draft &&
          (jobDetails.jobDetails?.jobType === JobType.Emergency ||
            jobDetails.jobDetails?.jobType === JobType.OneTime ||
            jobDetails.jobDetails?.jobType !== JobType.Recurring)
        }
        showDeleteIcon={
          jobStatus === JobStatuses.Draft ||
          jobStatus === JobStatuses.OpenToBid ||
          jobStatus === JobStatuses.PendingEstimation ||
          jobStatus === JobStatuses.PendingCustomerApproval ||
          jobStatus === JobStatuses.ClosedForBid
        }
        onDeleteClick={handleClickOpen}
        showConversationIcon={showConversationIcon()}
        onConversationButtonClick={handleConversationIconClick}
        onEditJobNameButtonClick={handleEditJobNameIconClick}
        showConversationTitle={showConversationTitle}
      />
      <CustomerContainer>
        <TabSection sx={{ height: '100%' }}>
          <TabsWrapper>
            <Tabs value={tabSelected} onChange={handleChangeTabs} aria-label="basic tabs">
              <TabTitle label={t('dashboard:jobDetails')} {...a11yProps(0)} />
              {role === UserType.Customer &&
                (jobStatus === JobStatuses.OpenToBid || jobStatus === JobStatuses.ClosedForBid) &&
                !location?.state?.isModifyJobDetails && <TabTitle label={t('dashboard:bids')} {...a11yProps(1)} />}
            </Tabs>
          </TabsWrapper>
          <TabPanel value={tabSelected} index={0} style={{ height: '100%' }}>
            {isLoading ? (
              <Loader>
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              </Loader>
            ) : (jobDetails?.facility && Object.keys(jobDetails?.facility).length) ||
              (jobDetails?.jobDetails && Object.keys(jobDetails?.jobDetails).length) ? (
              <FacilityAndVendorDetailsContainer>
                {location.state?.facilityItem?.bidStatus === SubmittedBidStatus.RevisionRequested &&
                  location.state?.isRequestRevision && (
                    <BidRequestRevisionComments
                      comment={customerComment}
                      hasJobCustodian={!!location.state?.facilityItem?.jobCustodian}
                    />
                  )}
                <FacilityDetails
                  data={jobDetails}
                  fetchJobDetails={fetchJobDetails}
                  isModifyJobDetails={location?.state?.isModifyJobDetails}
                />
                {jobStatus === JobStatus.Completed && role === UserType.Customer && (
                  <RatingSystem
                    title={ratingTitle}
                    ratingValue={ratingValue}
                    precisionValue={0.1}
                    readOnly={readOnly}
                    showLoader={isUpdateRatingLoading}
                    handleStarRating={handleStarRating}
                  />
                )}
                {(location.state?.facilityItem?.status !== JobStatus.Draft ||
                  (location.state?.facilityItem?.status !== JobStatus.Draft &&
                    jobDetails.executionType === 'subVendor')) &&
                ((isDistrictManager && executionType !== 'self') || role === UserType.Customer) ? (
                  <VendorDetails
                    data={jobDetails}
                    facilityItem={location.state?.facilityItem}
                    fetchJobDetails={fetchJobDetails}
                    isModifyJobDetails={false}
                  />
                ) : null}
                {role == UserType.Customer &&
                  (location.state?.jobStatus === JobStatuses.Created ||
                    location.state?.facilityItem?.status === JobStatuses.Created ||
                    location.state?.job?.status === JobStatuses.Created) && (
                    <AwardedBidDetails
                      serviceData={services}
                      finalQuote={finalQuote}
                      isServicesLoading={isServicesLoading}
                    />
                  )}
                {role == UserType.Vendor && bidsList.length > 0 && location.state?.job.dataStatus !== 'Awarded' && (
                  <BidDetails jobData={location.state?.job} bidsList={bidsList} />
                )}
                {role == UserType.Vendor &&
                (location.state?.job?.dataStatus === JobStatus.Awarded ||
                  location.state?.job?.jobStatus === WorkOrderStatus.Accepted) &&
                workOrderStatus &&
                (workOrderStatus === WorkOrderStatus.Accepted ||
                  workOrderStatus === WorkOrderStatus.Started ||
                  workOrderStatus === WorkOrderStatus.InProgress) ? (
                  <>
                    <AwardJob jobData={jobDetails} fetchJobDetails={fetchJobDetails} />
                  </>
                ) : null}
              </FacilityAndVendorDetailsContainer>
            ) : error ? (
              <ErrorMessage />
            ) : null}
          </TabPanel>
          {role === UserType.Customer &&
            (jobStatus === JobStatuses.OpenToBid || jobStatus === JobStatuses.ClosedForBid) && (
              <TabPanel value={tabSelected} index={1} style={{ height: '100%' }}>
                {isLoading ? (
                  <Loader>
                    <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                  </Loader>
                ) : (
                  <>
                    <DataGrid
                      columns={columns}
                      data={bidsList}
                      enableRowSelect={false}
                      enableColumnPinning={false}
                      errorMessageTitle={t('noData:noBidsYet')}
                      errorMessageDescription={t('noData:comeBack')}
                    />
                  </>
                )}
              </TabPanel>
            )}
        </TabSection>
        <DeleteJobs
          isApproveWindowOpen={dialogueBoxOpen}
          handleCloseApproveWindow={() => setDialogueBoxOpen(false)}
          deleteJobId={jobId}
          deleteJobName={updatedJobName}
          jobType=""
          fetchJobsView={fetchJobDetails}
          fetchData={() => {}}></DeleteJobs>
        <Modal
          open={isEditNameModalOpen}
          onClose={handleEditJobNameClose}
          primaryButtonLoading={isLoading}
          primaryButtonLabel={t('oneTimeJob:changeName')}
          primaryButtonVisible={true}
          secondaryButtonLabel={t('assignJob:cancel')}
          secondaryButtonVisible
          renderHeader={renderEditNameHeaderContent()}
          renderBody={renderEditNameBodyContent()}
          primaryAction={handleSubmit(handleChangeName)}
          secondaryAction={handleEditJobNameClose}
        />
      </CustomerContainer>
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default OneTimeJobDetails;
