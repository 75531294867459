/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Stack, Tabs, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import moment, { utc } from 'moment';
import { isEmpty } from 'lodash';

import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { ActionsColumn, JobLink, Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { Icons } from '../../../../Shared/Constants/Icons';
import {
  JobDetails,
  JobExecution,
  deepClone,
  formatDate,
  getAuthData,
  getStoredCustomerDetails,
  removeJobLocalData,
} from '../../../../Shared/Utilities/utils';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import {
  CurrencySymbol,
  FilterItem,
  JobStatus,
  JobStatuses,
  ModifyQuoteStatus,
  TransactionStatus,
} from '../../../../Shared/Constants/App';
import theme from '../../../../Shared/Themes/theme';
import { VendorType } from '../../../../Shared/Models/Vendors.Models';

import { TabSection, TabTitle } from '../../../Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { TabsWrapper } from '../../../Jobs/Components/JobView/JobsView.styles';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import DeleteJobs from '../../../Jobs/Components/DeleteJobDialog/DeleteJobDialog';
import { IJobRow, IJobs, ModifyQuoteDetails, IJobDetails } from '../../../Jobs/Models/JobsView.Model';
import ConfirmPopup from '../../../Jobs/Components/JobDetails/ConfirmPopup';
import ModifyPopUp from '../../../Jobs/Components/JobDetails/ModifyPopUp';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import WorkOrdersApiService from '../../../Jobs/Services/WorkOrdersService';

import CreateBidServices from '../../Services/CreateBidServices';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import VendorApiService from '../../Services/VendorService';
import {
  JobBidAdditionalExpenses,
  JobBidEstimation,
  JobBidSummary,
  JobService,
  ServiceCategoryV3,
  WorkOrderStatus,
  BidStatus,
  UpdateJobBidInput,
  BidDraftStep,
} from '../../../../API';
import { facilityTypes, getJobsTab } from '../../Models/constants';
import OngoingJobs from './OngoingJobsTab';
import { BidUpdateResult, TabPanelProps } from '../../Models/Jobs.Model';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';

function a11yProps(index: number): {
  id: string;
  'aria-controls': string;
} {
  return {
    id: `marketplace-tab-${index}`,
    'aria-controls': `marketplace-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`marketplace-tabpanel-${index}`}
      aria-labelledby={`marketplace-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

enum Text {
  NavigateToBid = '/create-bid',
  PageEdit = 'homePage:edit',
  TextsModify = 'altTexts:modify',
  ViewDetails = 'altTexts:viewDetails',
  NavigateToOneTimeJobDetails = '/onetimejobdetails',
}

const MarketplacePage = (): JSX.Element => {
  const jobsTab = getJobsTab();
  const { t } = useTranslation(['oneTimeJob', 'dashboard', 'tableHeader', 'altText', 'vendor', 'homePage']);
  const location = useLocation();
  const pathVariables = location?.pathname.split('/');
  const initialTabIndex = jobsTab.findIndex((tab) => tab.route === pathVariables[pathVariables.length - 1]);

  const [tabSelected, setTabSelected] = useState(initialTabIndex === -1 ? 0 : initialTabIndex);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setAnchorEl] = useState<null | HTMLElement>(null);
  const [jobs, setJobs] = useState<any[]>([]);
  const [selfPerformedJobs, setSelfPerformedJobs] = useState<any[]>([]);
  const [subContractedJobs, setSubContractedJobs] = useState<any[]>([]);
  const [bidsInProgressJobs, setBidsInProgressJobs] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const itemsPerPage = 10;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [selectedJobIdForCompletion, setSelectedJobIdForCompletion] = useState('');
  const [isCompleteJobLoading, setIsCompleteJobLoading] = useState<boolean>(false);
  const [isCompleteJobPopupOpen, setIsCompleteJobPopupOpen] = useState(false);
  const [modifyQuoteJobDetails, setModifyQuoteJobDetails] = useState<ModifyQuoteDetails>({} as ModifyQuoteDetails);
  const [isModifyQuoteLoading, setIsModifyQuoteLoading] = useState<boolean>(false);
  const [isModifyQuotePopupOpen, setIsModifyQuotePopupOpen] = useState(false);
  const [isBidRevisionPopupOpen, setIsBidRevisionPopupOpen] = useState(false);
  const [isBidRevisionLoading, setIsBidRevisionLoading] = useState<boolean>(false);
  const [bidRevisionJobDetails, setBidRevisionJobDetails] = useState<any>({});
  const [columnFilters, setColumnFilters] = useState<FilterItem[]>([]);

  const { isDistrictManager } = getAuthData();
  const [dialogueBoxOpen, setDialogueBoxOpen] = useState(false);
  const [deleteJobId, setDeleteJobId] = useState('');
  const [deleteJobName, setDeleteJobName] = useState('');
  const [modifiedJobsTab, setModifiedJobsTab] = useState(jobsTab);

  const [sortBy, setSortBy] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>();
  const [newSortOrder, setNewSortOrder] = useState('');

  const navigate = useNavigate();
  const viewDetailsText = t('homePage:viewDetails');

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleJobView = (): void => {
    console.log('Bid has been deleted successfully');
  };

  const handleClickOpen = (): void => {
    setDialogueBoxOpen(true);
  };

  const handleCompletionJobPopup = (): void => {
    setIsCompleteJobPopupOpen(!isCompleteJobPopupOpen);
  };

  const handleModifyQuotePopup = (): void => {
    setIsModifyQuotePopupOpen(!isModifyQuotePopupOpen);
  };

  const handleChange = (event: any, value: number): void => {
    setCurrentPage(value);
  };

  //  job name for 2 tabs
  const renderJobLinkCell = (
    renderedCellValue: string | React.ReactNode | null | undefined,
    row: any
  ): JSX.Element | '-' => {
    const { jobId, dataStatus } = row.original as unknown as IJobRow;

    const route = [JobStatuses.InProgress, JobStatuses.Completed].includes(dataStatus as JobStatuses)
      ? '/job-detail'
      : Text.NavigateToOneTimeJobDetails;
    const state = [JobStatuses.InProgress, JobStatuses.Completed].includes(dataStatus as JobStatuses)
      ? {
          facilityItem: { facilityId: row.original?.facilityId },
          customerId: row.original?.customerId,
          jobId,
        }
      : {
          facilityItem: row.original,
          facility: {
            ...row.original.facility,
            address: row.original.address,
            facilityType: row.original.facilityType,
          },
          job: row.original,
        };

    if (typeof renderedCellValue === 'string') {
      return (
        <JobLink to={`${route}?id=${jobId}`} state={state}>
          {renderedCellValue}
        </JobLink>
      );
    }

    return '-';
  };

  const onClickModifyQuote = (jobDetails: ModifyQuoteDetails): void => {
    setModifyQuoteJobDetails(jobDetails);
    setSelectedJobIdForCompletion(jobDetails.jobId);
    if ((jobDetails.jobDetails.jobStatus as ModifyQuoteStatus) === ModifyQuoteStatus.ContinueQuoteUpdate) {
      const modifiedJobDetails: IJobDetails = {
        ...jobDetails.jobDetails,
        jobServices: jobDetails.jobServices,
        profitMargin: jobDetails.bidSummaryResponse.profitMargin,
        isFlatMargin: jobDetails.bidSummaryResponse.isFlatMargin,
        isResubmittingBid: true,
      };
      if (
        (jobDetails.jobBids[0].dataStatus as BidStatus) !== BidStatus.R &&
        (jobDetails.jobBids[0].draftStep as BidDraftStep) === BidDraftStep.BidProposalSummary
      ) {
        navigate('/bid-review', {
          state: {
            bidDetails: modifiedJobDetails,
            isCreate: false,
            isEditable: false,
          },
        });
      } else {
        navigate(Text.NavigateToBid, {
          state: {
            jobDetails: modifiedJobDetails,
            jobId: jobDetails.jobId,
            customerId: jobDetails.customerId,
            isJobCreation: false,
          },
        });
      }
    } else {
      handleModifyQuotePopup();
    }
  };

  const renderCell = (renderedCellValue: React.ReactNode): string | number => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    if (renderedCellValue && Array.isArray(renderedCellValue)) {
      return renderedCellValue[0]?.finalQuote || renderedCellValue[0]?.finalQuote === 0
        ? `${CurrencySymbol.Dollar}${Number(renderedCellValue[0]?.finalQuote).toFixed(2)}`
        : '-';
    }
    return '-';
  };

  const formatDateCell = (cell: any): string => {
    const cellValue = cell.getValue();
    if (cellValue) {
      return utc(cellValue).format('MM/DD/YYYY');
    }
    return '-';
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderActionsCell = (row: any): JSX.Element => {
    const {
      jobId,
      jobName,
      jobType,
      customerId,
      customerEmail,
      customerName,
      startDate,
      stopDate,
      subJobId,
      subJobBidId,
      isCustomerApprovalAvailable,
      address,
      jobVendors,
      jobCustodian,
      bidSummaryResponse,
      jobBids,
      jobDetails,
      jobServices,
    } = row.original as unknown as IJobRow;
    const { customerId: storedCustomerId } = getStoredCustomerDetails();

    const isCompleteJobDisabled =
      isEmpty(bidSummaryResponse?.transactionStatus) ||
      bidSummaryResponse?.transactionStatus === TransactionStatus.PendingApproval ||
      bidSummaryResponse?.transactionStatus === TransactionStatus.Rejected ||
      !isCustomerApprovalAvailable;

    return (
      <ActionsColumn>
        {(row.original.jobStatus === JobStatus.Draft || row.original.jobStatus === JobStatus.OpenForBids) && (
          <Linking
            to={{ pathname: `/createOneTimeJob` }}
            state={{ jobRecord: jobs[row.index], isJobCreation: false, mediaFileIds: [] }}
            key={0}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <WhiteTooltip title={t('oneTimeJob:modifyJob')}>
                <img src={Icons.EditBlackIcon} alt={t(Text.TextsModify)} />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}

        {row.original.jobStatus === JobStatus.PendingEstimation && (
          <Linking
            to={{ pathname: `/create-bid` }}
            state={{
              jobDetails: {
                ...jobs[row.index],
                serviceSchedule: 'OneTime',
              },
              jobId,
              storedCustomerId,
              isJobCreation: false,
            }}
            key={1}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <WhiteTooltip title={t('oneTimeJob:continueEstimation')}>
                <img src={Icons.EditBlackIcon} alt={t(Text.TextsModify)} />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}

        {row.original.jobStatus === JobStatus.RevisionRequested && (
          <IconButton
            size="large"
            onClick={() => {
              setIsBidRevisionPopupOpen(true);
              setBidRevisionJobDetails({
                jobDetails: {
                  ...jobs[row.index],
                  serviceSchedule: 'OneTime',
                },
                jobId,
                storedCustomerId,
                isJobCreation: false,
              });
            }}
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            <WhiteTooltip title={t(Text.PageEdit)}>
              <img src={Icons.EditBlackIcon} alt={t(Text.TextsModify)} />
            </WhiteTooltip>
          </IconButton>
        )}

        {(row.original.jobStatus === JobStatus.Draft ||
          row.original.jobStatus === JobStatus.PendingEstimation ||
          row.original.jobStatus === JobStatus.PendingCustomerApproval ||
          row.original.jobStatus === JobStatus.OpenForBids ||
          row.original.jobStatus === JobStatus.ClosedForBid) && (
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              setDeleteJobId(jobId);
              setDeleteJobName(jobName);
              handleClickOpen();
            }}>
            <WhiteTooltip title={t('homePage:delete')}>
              <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} />
            </WhiteTooltip>
          </IconButton>
        )}
        {row.original.jobStatus === JobStatus.PendingCustomerApproval && (
          <Linking
            to={{ pathname: `/bid-review` }}
            state={{
              bidDetails: {
                ...jobs[row.index],
                facilityType: jobs[row.index].facilityType,
              },
              isCreate: false,
              isEditable: false,
            }}
            key={3}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <WhiteTooltip title={viewDetailsText}>
                <img src={Icons.EyeIcon} alt={t(Text.ViewDetails)} />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}
        {(row.original.jobStatus === JobStatus.InProgress || row.original.jobStatus === JobStatuses.Completed) && (
          <Linking
            to={{ pathname: `/job-detail` }}
            state={{
              facilityItem: { facilityId: row.original?.facilityId },
              customerId: row.original?.customerId,
              jobId,
            }}
            key={3}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <WhiteTooltip title={viewDetailsText}>
                <img src={Icons.EyeIcon} alt={t(Text.ViewDetails)} />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}
        {row.original.dataStatus === JobStatus.Awarded && (
          <IconButton
            size="small"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
              opacity: row.original.isDisableModifyQuoteJob ? '0.4' : '1',
            }}
            disabled={row.original.isDisableModifyQuoteJob}
            onClick={() => {
              onClickModifyQuote({
                jobId,
                jobName,
                jobType,
                customerId,
                customerEmail,
                customerName,
                subJobId,
                subJobBidId,
                isCustomerApprovalAvailable,
                startDate,
                stopDate,
                address,
                jobVendors,
                jobCustodian,
                jobBids,
                jobServices,
                jobDetails,
                bidSummaryResponse,
              });
            }}>
            <WhiteTooltip title={t('homePage:modify')}>
              <img src={Icons.RevisedBidIcon} width={24} height={24} alt={t('altTexts:reviseQuote')} />
            </WhiteTooltip>
          </IconButton>
        )}
        {(row.original.jobStatus === JobStatus.PendingEstimation ||
          row.original.jobStatus === JobStatus.RevisionRequested ||
          row.original.dataStatus === JobStatus.Awarded) && (
          <Linking
            to={{
              pathname: Text.NavigateToOneTimeJobDetails,
              search: `?id=${jobId}`,
            }}
            state={{
              facilityItem: jobs[row.index].jobDetails,
              facility: {
                ...jobs[row.index].facility,
                address: jobs[row.index].address,
                facilityType: jobs[row.index].facilityType,
              },
              job: jobs[row.index],
            }}
            key={2}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              {' '}
              <WhiteTooltip
                title={row.original.dataStatus === JobStatus.Awarded ? t('homePage:schedule') : t(Text.ViewDetails)}>
                <img
                  src={row.original.dataStatus === JobStatus.Awarded ? Icons.ClockIcon : Icons.EyeIcon}
                  alt={row.original.dataStatus === JobStatus.Awarded ? t('homePage:schedule') : t(Text.ViewDetails)}
                />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}
        {(row.original.dataStatus === JobStatus.Awarded || row.original.dataStatus === JobStatuses.InProgress) && (
          <IconButton
            size="small"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
              opacity: isCompleteJobDisabled ? 0.5 : 1,
            }}
            onClick={() => {
              if (!isCompleteJobDisabled) {
                setSelectedJobIdForCompletion(jobId);
                handleCompletionJobPopup();
              }
            }}
            disabled={isCompleteJobDisabled}>
            <WhiteTooltip title={t('homePage:complete')}>
              <img src={Icons.CompleteJobIcon} width={24} height={24} alt={t('altTexts:complete')} />
            </WhiteTooltip>
          </IconButton>
        )}
      </ActionsColumn>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderBidsInProgressActionsCell = (row: any): JSX.Element => {
    const {
      jobId,
      jobName,
      jobType,
      customerId,
      customerEmail,
      customerName,
      startDate,
      stopDate,
      subJobId,
      subJobBidId,
      isCustomerApprovalAvailable,
      address,
      bidSummaryResponse,
      jobBids,
      jobDetails,
      jobServices,
    } = row.original as unknown as IJobRow;
    const { jobVendors, jobCustodian } = row.original?.jobDetails as unknown as IJobRow;
    // const rowNo: string = row?.original.bidId || '';

    const createIconButton = (icon: React.ReactNode, onClickAction?: () => void, disabled: boolean = false) => (
      <IconButton size="large" onClick={onClickAction} disabled={disabled}>
        {icon}
      </IconButton>
    );

    const isCompleteJobDisabled =
      isEmpty(bidSummaryResponse?.transactionStatus) ||
      bidSummaryResponse?.transactionStatus === TransactionStatus.PendingApproval ||
      bidSummaryResponse?.transactionStatus === TransactionStatus.Rejected ||
      !isCustomerApprovalAvailable;

    const actionButtons = [
      {
        pathname: [JobStatuses.InProgress, JobStatuses.Completed].includes(row.original.dataStatus as JobStatuses)
          ? '/job-detail'
          : Text.NavigateToOneTimeJobDetails,
        search: `?id=${jobId}`,
        state: [JobStatuses.InProgress, JobStatuses.Completed].includes(row.original.dataStatus as JobStatuses)
          ? {
              facilityItem: { facilityId: bidsInProgressJobs[row.index]?.facilityId },
              customerId: bidsInProgressJobs[row.index]?.customerId,
              jobId,
            }
          : {
              facilityItem: bidsInProgressJobs[row.index].jobDetails,
              facility: {
                ...bidsInProgressJobs[row.index].facility,
                address: bidsInProgressJobs[row.index].address,
                facilityType: bidsInProgressJobs[row.index].facilityType,
              },
              job: bidsInProgressJobs[row.index],
            },
        icon: (
          <WhiteTooltip title={viewDetailsText}>
            <img src={Icons.EyeIcon} alt={t('altTexts:viewBidDetails')} />
          </WhiteTooltip>
        ),
        altText: 'view-bid-details-icon',
        onClickAction: () => handleJobView(),
      },

      ...(row.original.dataStatus === JobStatus.Awarded || row.original.dataStatus === JobStatuses.InProgress
        ? [
            {
              icon: (
                <WhiteTooltip title={t('homePage:complete')}>
                  <img src={Icons.CompleteJobIcon} alt={t('altTexts:completeJob')} />
                </WhiteTooltip>
              ),
              altText: 'complete-job-icon',
              onClickAction: () => {
                setSelectedJobIdForCompletion(jobId);
                handleCompletionJobPopup();
              },
              disabled: isCompleteJobDisabled,
            },
          ]
        : []),
    ];

    const isBidStatus = row.original.bidStatus === 'Bid Submitted';
    const isDraftStatus = row.original.bidStatus === 'Draft Saved';
    const subJobBid =
      row?.original?.jobStatus === JobStatus.RevisionRequested
        ? bidsInProgressJobs[row.index]?.subJobs?.[0]?.jobBids.filter((bid: any) => bid.dataStatus === 'R')
        : [];
    return (
      <ActionsColumn>
        {row.original.jobStatus === JobStatus.RevisionRequested && (
          <IconButton
            size="large"
            onClick={() => {
              setIsBidRevisionPopupOpen(true);
              setBidRevisionJobDetails({
                jobDetails: {
                  ...bidsInProgressJobs[row.index],
                  subJobBids: subJobBid,
                  serviceSchedule: 'OneTime',
                  bidId: bidsInProgressJobs[row.index]?.jobBids?.[0]?.bidId,
                  subJobBidId: subJobBid?.[0]?.bidId,
                  hasBidSelected: false,
                },
                jobId,
                customerId: bidsInProgressJobs[row.index].customerId,
                isJobCreation: false,
              });
            }}
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            <WhiteTooltip title={t(Text.PageEdit)}>
              <img src={Icons.EditBlackIcon} alt={t(Text.TextsModify)} />
            </WhiteTooltip>
          </IconButton>
        )}
        {row.original.dataStatus === JobStatus.Awarded && (
          <IconButton
            size="small"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
              opacity: row.original.isDisableModifyQuoteJob ? '0.4' : '1',
            }}
            disabled={row.original.isDisableModifyQuoteJob}
            onClick={() => {
              onClickModifyQuote({
                jobId,
                jobName,
                jobType,
                customerId,
                customerEmail,
                customerName,
                subJobId,
                subJobBidId,
                isCustomerApprovalAvailable,
                startDate,
                stopDate,
                address,
                jobVendors,
                jobCustodian,
                jobBids,
                jobServices,
                jobDetails,
                bidSummaryResponse,
              });
            }}>
            <WhiteTooltip title={t('homePage:modify')}>
              <img src={Icons.RevisedBidIcon} width={24} height={24} alt={t('altTexts:reviseQuote')} />
            </WhiteTooltip>
          </IconButton>
        )}
        {actionButtons.map((button, index) => {
          if (isBidStatus && button.altText === 'revise-bid-icon') {
            return null;
          }
          if (isDraftStatus && button.altText === 'revise-bid-icon') {
            return null;
          }
          return (
            <Linking
              key={(button.pathname || '') + index}
              to={{ pathname: button.pathname, search: button.search }}
              state={button.state}>
              {createIconButton(button.icon, button.onClickAction, button.disabled)}
            </Linking>
          );
        })}
        {(row.original.jobStatus === JobStatus.Draft ||
          row.original.jobStatus === JobStatus.PendingEstimation ||
          row.original.jobStatus === JobStatus.PendingCustomerApproval ||
          row.original.jobStatus === JobStatus.ClosedForBid) &&
          row.original.jobStatus === JobStatus.OpenForBids && (
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}
              onClick={() => {
                setDeleteJobId(jobId);
                setDeleteJobName(jobName);
                handleClickOpen();
              }}>
              <WhiteTooltip title={t('homePage:delete')}>
                <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} />
              </WhiteTooltip>
            </IconButton>
          )}
      </ActionsColumn>
    );
  };

  const handleChangeTabs = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
    const selectedTab = modifiedJobsTab[newValue];
    window.history.pushState(null, 'Peazy', `/vendor-jobs/${selectedTab.route}`);
    if (newValue === 1 || newValue === 4) {
      getBidsInProgressData();
    } else {
      getJobsData();
    }
  }, []);

  const getFacilityName = (mdFacilityType: string): string | undefined => {
    const facility = facilityTypes.find((facility) => facility.facilityType === mdFacilityType);
    return facility?.facilityName;
  };

  const getTotalServiceArea = (
    services: {
      jobId: string;
      mdServiceId: string;
      serviceMeasure: number;
      mdServiceUnits: string;
      actualServiceMeasure: string;
      actualMdServiceUnits: number;
    }[]
  ): number => {
    let totalArea = 0;
    services.forEach((service) => {
      if (service.actualServiceMeasure === 'sqft') {
        totalArea += parseFloat(service.actualMdServiceUnits.toString());
      }
    });
    return totalArea;
  };

  const getNumberOfDaysLeft = (
    jobDueDates: { jobId: string; mdDueType: string; dueDescription: string; dueDate: string }[]
  ): number | '-' => {
    const bidDueDateObj: any = jobDueDates.find((dateObj) => dateObj.mdDueType === 'Bid');
    if (bidDueDateObj) {
      const timeDifference = new Date(bidDueDateObj?.dueDate).getTime() - new Date().getTime();
      return Math.ceil(timeDifference / (1000 * 3600 * 24));
    } else {
      return '-';
    }
  };

  const getServiceVolume = (area: number): 'Small' | 'Medium' | 'Large' | undefined => {
    if (area < 5000) {
      return 'Small';
    } else if (area >= 5000 && area < 10000) {
      return 'Medium';
    } else if (area >= 10000) {
      return 'Large';
    }
  };

  const getServiceDuration = (startDate: string, stopDate: string): string => {
    const timeDifference = new Date(stopDate).getTime() - new Date(startDate).getTime();
    const numberOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
    if (numberOfDays === 0) {
      return 'One time';
    } else if (numberOfDays === 1) {
      return '1 day';
    } else if (numberOfDays % 7 === 0) {
      return String(numberOfDays / 7) + ' weeks';
    } else if (numberOfDays % 30 === 0 || numberOfDays % 30 === 1) {
      return String(Math.round(numberOfDays / 30)) + ' months';
    } else {
      return String(numberOfDays) + ' days';
    }
  };

  const getOTJStatus = (
    jobStatus: JobStatus,
    jobBids: JobBidSummary[]
  ):
    | JobStatus.PendingCustomerApproval
    | JobStatus.Awarded
    | JobStatus.PendingEstimation
    | JobStatus.Draft
    | JobStatus.RevisionRequested
    | JobStatus.OpenForBids
    | JobStatus.ClosedForBid
    | JobStatus.InProgress => {
    if (jobStatus === JobStatus.OpenToBid) {
      const submittedBids = jobBids.filter((bidItem: JobBidSummary) => bidItem.dataStatus === ('S' as BidStatus));
      const revisionRequested = jobBids.filter((bidItem: JobBidSummary) => bidItem.dataStatus === ('R' as BidStatus));
      const pendingApprovalBids = jobBids.filter(
        (bidItem: JobBidSummary) => bidItem.dataStatus === ('A' as any) && bidItem.draftStatus === ('C' as any)
      );
      if (revisionRequested?.length) {
        return JobStatus.RevisionRequested;
      } else if (submittedBids?.length || pendingApprovalBids?.length) {
        return JobStatus.PendingCustomerApproval;
      } else {
        return JobStatus.PendingEstimation;
      }
    } else if (jobStatus === JobStatus.Awarded) {
      return JobStatus.Awarded;
    }

    return jobStatus;
  };

  const getSubContractedJobStatus = (
    jobStatus: JobStatus,
    jobBids: JobBidSummary[]
  ):
    | JobStatus.Awarded
    | JobStatus.Draft
    | JobStatus.RevisionRequested
    | JobStatus.OpenForBids
    | JobStatus.InProgress => {
    if (jobStatus === JobStatus.OpenToBid) {
      if (jobBids.length && jobBids.filter((bid: any) => bid.dataStatus === 'R').length) {
        return JobStatus.RevisionRequested;
      } else {
        return JobStatus.OpenForBids;
      }
    } else if (jobStatus === JobStatus.Awarded) {
      return JobStatus.Awarded;
    } else if (jobStatus === JobStatus.InProgress) {
      return JobStatus.InProgress;
    } else {
      return JobStatus.Draft;
    }
  };

  const fetchUserData = async (): Promise<any> => {
    const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
    const userDetails = await AuthenticationService.getUserByCognitoUserId(loggedInUser?.username);
    return userDetails?.data?.userId;
  };

  const fetchJobsData = async (
    customerId: string,
    userId: any,
    pageNumber: number | undefined,
    sortBy: any,
    sortOrder: any,
    columnFilters: FilterItem[] = []
  ) => {
    const searchString = columnFilters.find((filter) => filter.id === 'jobName')?.value;
    const jobsDataResponse = await JobCreateApiService.getJobsForPrimeVendor(customerId, {
      userId,
      limit: 10,
      pageNumber,
      sortBy,
      sortOrder,
      searchString,
    });
    setTotalCount(jobsDataResponse.data?.metadata?.totalCount);
    return jobsDataResponse?.data?.data?.filter(
      (jobItem: { jobVendors: { mdVendorType: VendorType }[]; jobFacilities: { facilityId: string }[] }) =>
        jobItem.jobVendors[0]?.mdVendorType !== VendorType.All &&
        jobItem.jobVendors[0]?.mdVendorType !== VendorType.SearchVendors &&
        !isEmpty(jobItem.jobFacilities)
    );
  };

  const updateJobStatus = (
    job: { dataStatus: JobStatus; jobBids: JobBidSummary[] },
    bidSummaryResponse: { data: any; errors: never[] } | { data: null; errors: any }
  ) => {
    let jobStatus: string = getOTJStatus(job.dataStatus, job.jobBids);
    if (job.dataStatus === JobStatus.Awarded) {
      if (!bidSummaryResponse?.data?.isCustomerApprovalAvailable) {
        jobStatus = TransactionStatus.ContinueQuoteUpdate;
      } else if (bidSummaryResponse?.data?.transactionStatus === TransactionStatus.PendingApproval) {
        jobStatus = TransactionStatus.PendingApprovalFromCustomer;
      } else if (bidSummaryResponse?.data?.transactionStatus === TransactionStatus.Rejected) {
        jobStatus = TransactionStatus.CustomerRejectedTheBid;
      }
    }
    return jobStatus;
  };

  const fetchJobDetails = async (job: {
    jobFacilities: { facilityId: string }[];
    customerId: string;
    jobId: string;
    jobBids: { bidId: string }[];
  }) => {
    const facilityResponse = await FacilityApiService.facility(job.jobFacilities[0].facilityId, job.customerId);
    const bidEstimations = await CreateBidServices.allJobBidEstimation(job?.jobId, job.jobBids[0]?.bidId ?? '');
    const serviceCategories = await getServiceCategories(job.customerId);
    const allAdditionalExpenses = await CreateBidServices.allJobBidAdditionalExpenses(
      job?.jobId,
      job.jobBids?.[0]?.bidId ?? ''
    );
    const bidSummaryResponse = job?.jobBids[0]?.bidId
      ? await VendorApiService.getBidSummary(job.jobBids[0]?.bidId)
      : null;
    const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(job.customerId, job?.jobId);

    return [
      facilityResponse,
      bidEstimations,
      serviceCategories,
      allAdditionalExpenses,
      bidSummaryResponse,
      jobSummaryResponse,
    ];
  };

  const processBidSummary = (updatedJob: any, bidSummaryResponse: any) => {
    updatedJob.jobStatus = updateJobStatus(updatedJob, bidSummaryResponse);
    updatedJob.status = updatedJob.jobStatus;

    if (bidSummaryResponse?.data && bidSummaryResponse?.errors.length === 0) {
      updatedJob.bidSummaryResponse = bidSummaryResponse.data;
      updatedJob.isCustomerApprovalAvailable = bidSummaryResponse?.data?.isCustomerApprovalAvailable;
    }

    return updatedJob;
  };

  const getExecutionType = (job: any): JobDetails.Self | JobDetails.SubVendor => {
    return job.jobVendors[0]?.mdVendorType === JobExecution.SubVendor ? JobDetails.SubVendor : JobDetails.Self;
  };

  const getEstimationType = (job: any) => {
    return job?.subJobs?.length && job?.subJobs?.[0]?.jobBids?.length === 1
      ? JobDetails.EstimateOutsidePeazy
      : JobDetails.EstimateWithinPeazy;
  };

  const isDisableModifyQuote = (jobSummaryResponse: any, updatedJob: any): boolean => {
    return (
      // Below line is commented as WinTeam sync is not working as expected. Once it is fixed, uncomment the below line
      // isEmpty(jobSummaryResponse?.data?.actualId ?? '') ||
      updatedJob.jobStatus === ModifyQuoteStatus.PendingApprovalFromCustomer
    );
  };

  const getJobFacilityDetails = (facilityResponse: any, job: any) => {
    return {
      location: facilityResponse.data?.address?.addressName,
      facilityType: getFacilityName(job.facility?.mdFacilityType),
      buildingName: facilityResponse.data?.buildingName,
      address: facilityResponse.data?.address,
      facilityId: job.jobFacilities[0]?.facilityId,
    };
  };

  const getAdditionalJobDetails = (
    job: any,
    facilityResponse: any,
    bidEstimations: any,
    serviceCategories: any,
    allAdditionalExpenses: any
  ) => {
    const area = getTotalServiceArea(job.jobServices ?? []);
    return {
      id: job.jobId,
      jobId: job.jobId,
      createdOn: job.createdOn,
      area: `${area} sqft`,
      duration: getServiceDuration(job.startDate, job.stopDate),
      volume: getServiceVolume(area),
      noOfDaysLeft: getNumberOfDaysLeft(job.jobDueDates ?? []),
      percentageMatch: `${job.matchPercentage}%`,
      jobName: job.jobName,
      startDate: job.startDate,
      stopDate: job.stopDate,
      lastActivity: null,
      customerId: job.customerId,
      revisionRequests: [],
      selectedProfitOption: job.jobBids.length > 0 && job.jobBids[0]?.isFlatMargin ? '$' : '%',
      jobEstimations: bidEstimations.data ?? [],
      jobServices: getServicesData(bidEstimations, serviceCategories, job),
      jobBids: job.jobBids,
      additionalExpenseData: getAdditionalExpenses(allAdditionalExpenses),
    };
  };

  const updateSubJobDetails = async (updatedJob: any, job: any, serviceCategories: any) => {
    updatedJob.subJobId = job.subJobs[0].jobId;
    updatedJob.subJobBids = job.subJobs[0].jobBids;
    updatedJob.subJobBidId = job.subJobs[0]?.jobBids?.[0]?.bidId;

    const subBidEstimations = await CreateBidServices.allJobBidEstimation(
      job.subJobs[0].jobId,
      job.subJobs?.[0]?.jobBids?.[0]?.bidId ?? ''
    );

    subBidEstimations.data = subBidEstimations.data ?? [];
    updatedJob.subJobEstimations = subBidEstimations.data ?? [];
    updatedJob.subJobServices = getSubJobServices(job, updatedJob.jobServices, subBidEstimations, serviceCategories);

    return updatedJob;
  };

  const updateRevisionRequestedJobDetails = async (updatedJob: any, job: any) => {
    const [bidEstimations, serviceCategories, allAdditionalExpenses] = await getSubContractedBidData(job);
    updatedJob.selectedProfitOption = job.jobBids.length > 0 && job.jobBids[0]?.isFlatMargin ? '$' : '%';
    updatedJob.jobEstimations = bidEstimations;
    updatedJob.jobServices = getServicesData(bidEstimations, serviceCategories, job);
    updatedJob.jobBids = job.jobBids;
    updatedJob.additionalExpenseData = getAdditionalExpenses(allAdditionalExpenses);

    return updatedJob;
  };

  const getSubJobServices = (
    job: { subJobs: { jobServices: { actualMdServiceUnits: any }[] }[] },
    jobServices: any,
    subBidEstimations: { data: any; errors: never[] } | { data: null; errors: any },
    serviceCategories: any[]
  ) => {
    if (subBidEstimations?.data?.length > 0) {
      return subBidEstimations?.data?.map((bidEstimationItem: JobBidEstimation) => {
        const selectedService: any = serviceCategories?.find(
          (serviceCategoryItem: ServiceCategoryV3) =>
            serviceCategoryItem.serviceCategoryId === bidEstimationItem?.mdCategoryId
        );
        return {
          service: selectedService?.serviceCategoryName ?? '',
          id: 0,
          quantity: bidEstimationItem.quantity,
          unit: job?.subJobs[0]?.jobServices[0]?.actualMdServiceUnits ?? bidEstimationItem.mdUnits,
          mdServiceId: bidEstimationItem.mdCategoryId,
          numOfHours: bidEstimationItem.quantity,
          ratePerHour: bidEstimationItem.rate,
          totalJobEstimation: bidEstimationItem.totalCost,
          totalServices: 1,
          serviceMeasure: bidEstimationItem?.mdMeasureType,
          estimationId: bidEstimationItem?.estimationId,
        };
      });
    } else {
      return jobServices;
    }
  };

  const getJobsData = useCallback(
    async (pageNumber?: number, sortBy?: any, sortOrder?: any, columnFilters: FilterItem[] = []) => {
      try {
        setIsLoading(true);
        if (sortOrder) {
          sortOrder = 'desc';
        } else {
          if (sortBy) sortOrder = 'asc';
        }

        let jobsData;
        if (tabSelected === 0) {
          const { customerId }: { customerId: string } = getStoredCustomerDetails() || '';
          const userId = await fetchUserData();
          jobsData = await fetchJobsData(customerId, userId, pageNumber, sortBy, sortOrder, columnFilters);
          if (!jobsData) {
            setJobs([]);
            setSelfPerformedJobs([]);
          }
        }
        const updatedData = await Promise.all(
          jobsData?.map(async (job: any) => {
            const [
              facilityResponse,
              bidEstimations,
              serviceCategories,
              allAdditionalExpenses,
              bidSummaryResponse,
              jobSummaryResponse,
            ] = await fetchJobDetails(job);

            allAdditionalExpenses.data = allAdditionalExpenses.data ?? [];

            let updatedJob: any = { ...job };

            if (job?.jobBids?.[0]?.bidId) {
              updatedJob = processBidSummary(updatedJob, bidSummaryResponse);
            } else {
              updatedJob.jobStatus = updateJobStatus(updatedJob, bidSummaryResponse);
            }

            updatedJob = {
              ...updatedJob,
              isDisableModifyQuoteJob: isDisableModifyQuote(jobSummaryResponse, updatedJob),
              executionType: getExecutionType(job),
              estimationType: getEstimationType(job),
              ...getJobFacilityDetails(facilityResponse, job),
              ...getAdditionalJobDetails(
                job,
                facilityResponse,
                bidEstimations,
                serviceCategories,
                allAdditionalExpenses
              ),
            };

            if (job?.subJobs?.length) {
              updatedJob = await updateSubJobDetails(updatedJob, job, serviceCategories);
            }

            updatedJob.jobDetails = { ...updatedJob, ...job };
            if (updatedJob.jobStatus === JobStatus.RevisionRequested) {
              updatedJob = await updateRevisionRequestedJobDetails(updatedJob, job);
            }

            return updatedJob;
          })
        );
        setJobs(updatedData);
        setSelfPerformedJobs(updatedData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log('error', error);
      }
    },
    []
  );

  const handleRevisionRequest = async (job: any, updatedJob: any) => {
    if (updatedJob.jobStatus === JobStatus.RevisionRequested) {
      updatedJob = await updateRevisionRequestedJobDetails(updatedJob, job);
      if (!isEmpty(updatedJob.subJobs)) {
        updatedJob.subJobServices = await getSubContractedSubJobServices(updatedJob.jobServices, job);
      }
    }
    return updatedJob;
  };

  const handleSubJobs = async (job: any, updatedJob: any, serviceCategories: any) => {
    if (job?.subJobs?.length) {
      updatedJob.subJobs = job.subJobs;
      return await updateSubJobDetails(updatedJob, job, serviceCategories);
    }
    return updatedJob;
  };

  const updateJobWithBidSummary = (job: any, updatedJob: any, bidSummaryResponse: any) => {
    if (bidSummaryResponse?.data && bidSummaryResponse?.errors.length === 0) {
      updatedJob.bidSummaryResponse = bidSummaryResponse.data;
      updatedJob.isCustomerApprovalAvailable = bidSummaryResponse?.data?.isCustomerApprovalAvailable;
      if (job.dataStatus === JobStatus.Awarded) {
        if (!bidSummaryResponse?.data?.isCustomerApprovalAvailable) {
          updatedJob.jobStatus = TransactionStatus.ContinueQuoteUpdate;
        } else if (bidSummaryResponse?.data?.transactionStatus === TransactionStatus.PendingApproval) {
          updatedJob.jobStatus = TransactionStatus.PendingApprovalFromCustomer;
        } else if (bidSummaryResponse?.data?.transactionStatus === TransactionStatus.Rejected) {
          updatedJob.jobStatus = TransactionStatus.CustomerRejectedTheBid;
        }
      }
    }
    return updatedJob;
  };

  const determineJobStatus = (
    job: any
  ):
    | JobStatus.Awarded
    | JobStatus.Draft
    | JobStatus.RevisionRequested
    | JobStatus.OpenForBids
    | JobStatus.ClosedForBid
    | JobStatuses.Draft
    | JobStatuses.Awarded
    | JobStatuses.Completed
    | JobStatuses.PendingCustomerApproval
    | JobStatus.InProgress => {
    const currentDate = new Date();
    if (job?.dataStatus === JobStatuses.OpenToBid && job?.jobBids.some((bid: any) => bid.dataStatus === 'S')) {
      return JobStatuses.PendingCustomerApproval;
    } else if (job?.jobBids.some((bid: any) => bid.dataStatus === 'A' && bid.draftStatus === 'C')) {
      return JobStatuses.PendingCustomerApproval;
    } else if (job?.jobBids.some((bid: JobBidSummary) => bid.dataStatus === ('R' as BidStatus))) {
      return JobStatus.RevisionRequested;
    } else if (job?.dataStatus === JobStatuses.Awarded) {
      return JobStatuses.Awarded;
    } else if (job?.dataStatus === JobStatuses.Draft) {
      return JobStatuses.Draft;
    } else if (job?.dataStatus === JobStatuses.Completed) {
      return JobStatuses.Completed;
    } else if (job?.dataStatus === JobStatus.InProgress) {
      return JobStatus.InProgress;
    } else {
      return moment(formatDate(job.startDate, 'YYYY-MM-DD')).isBefore(moment(moment(currentDate).format('YYYY-MM-DD')))
        ? JobStatus.ClosedForBid
        : getSubContractedJobStatus(job.dataStatus, job.jobBids);
    }
  };

  const getSubContractedBidData = async (job: any) => {
    const bidEstimations = await CreateBidServices.allJobBidEstimation(job?.jobId, job.jobBids[0]?.bidId ?? '');
    const serviceCategories = await getServiceCategories(job.customerId);
    bidEstimations.data = bidEstimations.data ?? [];
    const allAdditionalExpenses = await CreateBidServices.allJobBidAdditionalExpenses(
      job?.jobId,
      job.jobBids?.[0]?.bidId ?? ''
    );
    allAdditionalExpenses.data = allAdditionalExpenses.data ?? [];
    return [bidEstimations, serviceCategories, allAdditionalExpenses];
  };

  const getSubContractedSubJobServices = async (jobServices: any, job: any) => {
    const subBidEstimations = await CreateBidServices.allJobBidEstimation(
      job.subJobs[0].jobId,
      job.subJobs[0].jobBids[0].bidId ?? ''
    );
    const bidData = job.subJobs[0].jobBids.find((bid: any) => bid.dataStatus === 'R');
    return jobServices.map((service: any) => {
      return {
        ...service,
        totalJobEstimation: bidData.finalQuote,
        estimationId:
          subBidEstimations.data && !isEmpty(subBidEstimations.data) ? subBidEstimations?.data[0]?.estimationId : '',
      };
    });
  };

  const getServicesData = (bidEstimations: any, serviceCategories: ServiceCategoryV3[], job: any) => {
    return bidEstimations?.data?.length
      ? bidEstimations?.data?.map((bidEstimationItem: JobBidEstimation) => {
          const selectedService: any = serviceCategories.find(
            (serviceCategoryItem: ServiceCategoryV3) =>
              serviceCategoryItem?.serviceCategoryId === bidEstimationItem?.mdCategoryId
          );
          return {
            service: selectedService?.serviceCategoryName ?? '',
            id: 0,
            quantity: bidEstimationItem?.quantity,
            unit: bidEstimationItem?.mdUnits,
            mdServiceId: bidEstimationItem?.mdCategoryId,
            numOfHours: bidEstimationItem?.quantity,
            ratePerHour: bidEstimationItem?.rate,
            totalJobEstimation: bidEstimationItem?.totalCost,
            totalServices: 1,
            serviceMeasure: bidEstimationItem?.mdMeasureType,
            estimationId: bidEstimationItem?.estimationId,
          };
        })
      : job?.jobServices.map((jobServiceItem: JobService) => {
          const selectedService: any = serviceCategories?.find(
            (serviceCategoryItem: ServiceCategoryV3) =>
              serviceCategoryItem?.serviceCategoryId === jobServiceItem?.mdServiceId
          );
          return {
            service: selectedService?.serviceCategoryName ?? '',
            id: 0,
            quantity: 0,
            unit: jobServiceItem?.mdServiceUnits,
            mdServiceId: jobServiceItem?.mdServiceId,
            numOfHours: 0,
            ratePerHour: 0,
            totalJobEstimation: 0,
            totalServices: 1,
            serviceMeasure: jobServiceItem?.serviceMeasure,
          };
        });
  };

  const getAdditionalExpenses = (allAdditionalExpenses: any) => {
    return allAdditionalExpenses?.data?.map((expenseItem: JobBidAdditionalExpenses) => {
      return {
        name: expenseItem.name,
        totalJobEstimation: expenseItem.rate,
        mdServiceId: expenseItem.mdServiceCategoryId,
        jobId: '',
        additionalCategoryId: expenseItem.mdAdditionalExpenseCategory,
        bidAdditionalExpenseId: expenseItem.bidAdditionalExpenseId,
      };
    });
  };

  const getServiceCategories = async (customerId: string) => {
    try {
      const masterData = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      return masterData?.data ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const getBidsInProgressData = async (
    pageNumber?: number,
    sortBy?: any,
    sortOrder?: any,
    columnFilters: FilterItem[] = []
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const { customerId }: { customerId: string } = getStoredCustomerDetails() || '';

      const userId = await fetchUserData();

      if (sortOrder) {
        sortOrder = 'desc';
      } else {
        if (sortBy) sortOrder = 'asc';
      }
      const searchString = columnFilters.find((filter) => filter.id === 'jobName')?.value;

      const jobsData = await JobCreateApiService.getJobsForPrimeVendor(customerId, {
        userId: userId,
        limit: 10,
        pageNumber: pageNumber,
        mdVendorType: 'All,SearchVendors',
        sortBy,
        sortOrder,
        searchString,
      });

      setTotalCount(jobsData.data?.metadata?.totalCount);
      const bidsInProgressData = jobsData?.data?.data?.filter(
        (jobItem: any) =>
          jobItem.jobVendors[0]?.mdVendorType === VendorType.All ||
          jobItem.jobVendors[0]?.mdVendorType === VendorType.SearchVendors
      );
      if (!bidsInProgressData) {
        setBidsInProgressJobs([]);
        setSubContractedJobs([]);
      }

      const modifiedData = await Promise.all(
        bidsInProgressData?.map(async (job: any) => {
          const [
            facilityResponse,
            bidEstimations,
            serviceCategories,
            allAdditionalExpenses,
            bidSummaryResponse,
            jobSummaryResponse,
          ] = await fetchJobDetails(job);

          allAdditionalExpenses.data = allAdditionalExpenses.data ?? [];

          let updatedJob: any = {};
          updatedJob.jobStatus = determineJobStatus(job);

          if (job?.jobBids[0]?.bidId) {
            updatedJob = updateJobWithBidSummary(job, updatedJob, bidSummaryResponse);
          }

          updatedJob.executionType = JobDetails.SubVendor;
          updatedJob.estimationType = JobDetails.EstimateWithinPeazy;

          updatedJob.status = getSubContractedJobStatus(job.dataStatus, job.jobBids);

          updatedJob.jobVendors = job.jobVendors;
          updatedJob.jobCustodian = job?.jobCustodian;

          updatedJob = {
            ...updatedJob,
            isDisableModifyQuoteJob: isDisableModifyQuote(jobSummaryResponse, updatedJob),
            ...getJobFacilityDetails(facilityResponse, job),
            ...getAdditionalJobDetails(job, facilityResponse, bidEstimations, serviceCategories, allAdditionalExpenses),
            ...(await handleSubJobs(job, updatedJob, serviceCategories)),
            ...handleRevisionRequest(job, updatedJob),
          };
          updatedJob.customerEmail = job.customerEmail;

          updatedJob.jobDetails = { ...updatedJob, ...job };

          updatedJob.specialInstructions = job.specialInstructions;
          updatedJob.bidCount = 0;
          if (job?.subJobs?.length) {
            updatedJob.subJobId = job.subJobs[0].jobId;
            updatedJob.bidCount = job.subJobs[0].bidCount ?? 0;
          }
          updatedJob.intId = job.intId;
          updatedJob.dataStatus = job?.dataStatus;
          return updatedJob;
        })
      );
      setBidsInProgressJobs(modifiedData);
      setSubContractedJobs(modifiedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  const handleOnCompleteJob = async (): Promise<void> => {
    try {
      setIsCompleteJobLoading(true);
      const workOrderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: selectedJobIdForCompletion });
      if (isEmpty(workOrderResponse.errors)) {
        const workOrderData = workOrderResponse?.data[0];
        const workOrderId = workOrderData?.workOrderId;
        const updateWorkOrderStatusResponse = await WorkOrdersApiService.updateWorkOrderStatus({
          workOrderId: workOrderId ?? '',
          status: WorkOrderStatus.Completed,
        });
        if (!isEmpty(updateWorkOrderStatusResponse.errors)) {
          setSnackbarMessage(`${t('vendor:errorOccurred')}`);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else if (
          isEmpty(updateWorkOrderStatusResponse?.errors ?? []) &&
          !isEmpty(updateWorkOrderStatusResponse?.data ?? [])
        ) {
          getJobsData();
          setSnackbarMessage(`${t('assignJob:markedAsComplete')}`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setIsCompleteJobPopupOpen(false);
        }
      } else {
        setSnackbarMessage(`${t('vendor:errorOccurred')}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e: any) {
      setSnackbarMessage(e);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsCompleteJobLoading(false);
    }
  };

  const handleCloseBidRevision = (): void => {
    setIsBidRevisionPopupOpen(false);
  };

  const extractBidInfo = (bidDetails: {
    jobVendors: any;
    jobCustodian: any;
    jobBids: any;
    subJobBidId: any;
    subJobBids: any;
    subJobs: any;
    bidSummaryResponse: any;
  }) => {
    const { jobBids, subJobBidId, subJobBids, subJobs, bidSummaryResponse } = bidDetails;
    const bidId = jobBids[0].bidId;
    const subBidId = subJobBidId
      ? subJobBidId
      : subJobBids?.length
        ? subJobBids[0]?.bidId
        : subJobs?.length
          ? subJobs[0]?.jobBids[0]?.bidId
          : '';
    const vendorId = bidSummaryResponse?.vendorId ? bidSummaryResponse?.vendorId : jobBids[0]?.vendorId;
    const updatedMainBidVersion = (bidSummaryResponse?.bidVersion ?? 0) + 1;
    const updatedSubBidVersion = updatedMainBidVersion;
    return { bidId, subBidId, vendorId, updatedMainBidVersion, updatedSubBidVersion };
  };

  const updateSelfPerformBid = async (
    vendorId: string,
    bidId: string,
    bidVersion: number
  ): Promise<BidUpdateResult> => {
    const updateBidInput: UpdateJobBidInput = { dataStatus: BidStatus.R, bidVersion };
    const response = await CreateBidServices.updateBid(vendorId, bidId, updateBidInput);
    return handleBidResponse(response);
  };

  const updateMainAndSubBid = async (
    vendorId: string,
    bidId: string,
    subBidId: string,
    mainBidVersion: number,
    subBidVersion: number
  ): Promise<BidUpdateResult> => {
    const updateMainBidInput = { dataStatus: BidStatus.R, bidVersion: mainBidVersion };
    const updateSubBidInput = { dataStatus: BidStatus.R, bidVersion: subBidVersion };

    const [mainResponse, subResponse] = await Promise.all([
      CreateBidServices.updateBid(vendorId, bidId, updateMainBidInput),
      CreateBidServices.updateBid(vendorId, subBidId, updateSubBidInput),
    ]);

    const mainResult = handleBidResponse(mainResponse);
    const subResult = handleBidResponse(subResponse);

    return {
      isUpdateBidSuccess: mainResult.isUpdateBidSuccess && subResult.isUpdateBidSuccess,
      updateBidFailureError: mainResult.updateBidFailureError || subResult.updateBidFailureError,
      isMainBidVersionFailed: mainResult.isMainBidVersionFailed,
      isSubBidVersionFailed: subResult.isSubBidVersionFailed,
    };
  };

  const handleBidUpdateResult = async (
    result: BidUpdateResult,
    vendorId: string,
    bidId: string,
    subBidId: string,
    mainBidVersion: number,
    subBidVersion: number
  ): Promise<void> => {
    if (result.isUpdateBidSuccess) {
      navigateToCreateBid(bidRevisionJobDetails);
    } else if (
      !isEmpty(result.updateBidFailureError) ||
      result.isMainBidVersionFailed ||
      result.isSubBidVersionFailed
    ) {
      setSnackbarMessage('Error occurred while revising the bid');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      const isBothMainAndSubBidFailed = result.isMainBidVersionFailed && result.isSubBidVersionFailed;
      if (!isBothMainAndSubBidFailed) {
        if (result.isMainBidVersionFailed) {
          await resetBidVersion(vendorId, bidId, mainBidVersion - 1);
        }
        if (result.isSubBidVersionFailed) {
          await resetBidVersion(vendorId, subBidId, subBidVersion - 1);
        }
      }
    }
  };

  const handleBidResponse = (response: any): BidUpdateResult => {
    const isSuccess =
      !isEmpty(response.data) &&
      isEmpty(response?.data?.errorCode) &&
      isEmpty(response?.errors) &&
      response?.data?.bidVersion !== null;
    return {
      isUpdateBidSuccess: isSuccess,
      updateBidFailureError: response?.errors?.[0]?.message || response?.data?.errorCode,
      isMainBidVersionFailed: response?.errors?.[0]?.message || response?.data?.bidVersion === null,
    };
  };

  const resetBidVersion = async (vendorId: string, bidId: string, bidVersion: number): Promise<void> => {
    const updateBidInput: UpdateJobBidInput = { dataStatus: BidStatus.R, bidVersion };
    await CreateBidServices.updateBid(vendorId, bidId, updateBidInput);
  };

  const navigateToCreateBid = (bidDetails: any): void => {
    setTimeout(() => {
      navigate(Text.NavigateToBid, { state: { ...bidDetails } });
    }, 500);
  };

  const handleBidRevisionError = (error: any): void => {
    console.log('Bid revision error: ', error);
    setSnackbarMessage(error.toString());
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };

  const handleBidRevision = async (): Promise<void> => {
    setIsBidRevisionLoading(true);
    try {
      const { jobVendors, jobCustodian, isCustomerApprovalAvailable } = bidRevisionJobDetails.jobDetails;

      const bidDetails = bidRevisionJobDetails.jobDetails;
      const { bidId, subBidId, vendorId, updatedMainBidVersion, updatedSubBidVersion } = extractBidInfo(bidDetails);

      const isOTJFlow = !isEmpty(jobCustodian);
      const jobHasSubJobs = isOTJFlow && jobVendors[0]?.mdVendorType !== VendorType.SelfPerform;
      const isSelfPerformJob = isOTJFlow && jobVendors[0]?.mdVendorType === VendorType.SelfPerform;
      let result: any = {};
      if (isCustomerApprovalAvailable) {
        if (isSelfPerformJob) {
          result = await updateSelfPerformBid(vendorId, bidId, updatedMainBidVersion);
        } else if (jobHasSubJobs) {
          result = await updateMainAndSubBid(vendorId, bidId, subBidId, updatedMainBidVersion, updatedSubBidVersion);
        }
      } else {
        result = { isUpdateBidSuccess: true };
      }
      await handleBidUpdateResult(result, vendorId, bidId, subBidId, updatedMainBidVersion, updatedSubBidVersion);
    } catch (error: any) {
      handleBidRevisionError(error);
    }
    setIsBidRevisionLoading(false);
  };

  const handleNewBidUpdateResult = async (
    result: BidUpdateResult,
    vendorId: string,
    bidId: string,
    subBidId: string,
    mainBidVersion: number,
    subBidVersion: number,
    modifyQuoteJobDetails: any
  ): Promise<void> => {
    if (result.isUpdateBidSuccess) {
      navigateToCreateBidFromModifyQuote(modifyQuoteJobDetails);
    } else {
      setSnackbarMessage('Error occurred while modifying the bid');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);

      if (!(result.isMainBidVersionFailed && result.isSubBidVersionFailed)) {
        if (result.isMainBidVersionFailed) {
          await resetBidVersion(vendorId, bidId, mainBidVersion - 1);
        }
        if (result.isSubBidVersionFailed) {
          await resetBidVersion(vendorId, subBidId, subBidVersion - 1);
        }
      }
    }
  };

  // const storeBidData = ({
  //   jobId,
  //   jobName,
  //   jobType,
  //   jobVendors,
  //   address,
  //   jobServices,
  //   startDate,
  //   stopDate,
  //   mdVendorType,
  //   bidId,
  //   updatedMainBidVersion,
  // }: any) => {
  //   const bidData = processBidData({
  //     jobId,
  //     jobName,
  //     jobType,
  //     jobVendors,
  //     address,
  //     jobServices,
  //     startDate,
  //     stopDate,
  //     mdVendorType,
  //     bidId,
  //     updatedMainBidVersion,
  //   });
  //   // localStorage.setItem('BidCreate', JSON.stringify(bidData));
  // };

  const storeSubBidData = (): void => {
    // localStorage.setItem('SubBidCreate', JSON.stringify(subBidData));
  };

  const handleCustomerApproval = async ({
    isOTJFlow,
    mdVendorType,
    vendorId,
    bidId,
    subBidId,
    updatedMainBidVersion,
    updatedSubBidVersion,
    isCustomerApprovalAvailable,
  }: any) => {
    const isSelfPerformJobs = isOTJFlow && mdVendorType === VendorType.SelfPerform;
    // const isSubVendorInsidePeazy = isOTJFlow && mdVendorType === VendorType.SubVendor;
    const jobHasSubJobs = isOTJFlow && mdVendorType !== VendorType.SelfPerform;

    let result: any = {};
    if (isCustomerApprovalAvailable) {
      if (isSelfPerformJobs) {
        result = await updateSelfPerformBid(vendorId, bidId, updatedMainBidVersion);
      } else if (jobHasSubJobs) {
        result = await updateMainAndSubBid(vendorId, bidId, subBidId, updatedMainBidVersion, updatedSubBidVersion);
      }
    } else {
      result = { isUpdateBidSuccess: true };
    }
    return result;
  };

  // const processBidData = ({
  //   jobId,
  //   jobName,
  //   jobType,
  //   jobVendors,
  //   address,
  //   jobServices,
  //   startDate,
  //   stopDate,
  //   mdVendorType,
  //   bidId,
  //   updatedMainBidVersion,
  // }: any) => ({
  //   bidId,
  //   jobId: jobId ?? '',
  //   jobServices,
  //   startDate: startDate ?? '',
  //   stopDate: stopDate ?? '',
  //   address: address || {},
  //   jobName: jobName ?? '',
  //   jobType,
  //   jobVendors: jobVendors ?? [],
  //   mdVendorType,
  //   isResubmittingBid: true,
  //   bidVersion: updatedMainBidVersion,
  // });

  const navigateToCreateBidFromModifyQuote = ({
    jobDetails,
    bidSummaryResponse,
    jobId,
    customerId,
    jobServices,
  }: any): void => {
    const modifiedJobDetails: IJobDetails = {
      ...jobDetails,
      jobServices,
      profitMargin: bidSummaryResponse.profitMargin,
      isFlatMargin: bidSummaryResponse.isFlatMargin,
      isResubmittingBid: true,
    };

    setTimeout(() => {
      navigate(Text.NavigateToBid, {
        state: {
          jobDetails: modifiedJobDetails,
          jobId,
          customerId,
          isJobCreation: false,
        },
      });
    }, 500);
  };

  const resubmitHandler = async (): Promise<void> => {
    try {
      setIsModifyQuoteLoading(true);
      const {
        // jobId,
        // jobName,
        // jobType,
        isCustomerApprovalAvailable,
        jobVendors,
        jobCustodian,
        // address,
        bidSummaryResponse,
        subJobBidId,
        jobBids,
        // jobServices,
        // startDate,
        // stopDate,
      } = modifyQuoteJobDetails ?? {};

      const mdVendorType = jobVendors?.[0]?.mdVendorType ?? '';
      const vendorId = bidSummaryResponse?.vendorId ? bidSummaryResponse?.vendorId : jobVendors?.[0]?.vendorId;

      const isOTJFlow = !isEmpty(jobCustodian);

      const updatedMainBidVersion = (bidSummaryResponse?.bidVersion ?? 0) + 1;
      const updatedSubBidVersion = (bidSummaryResponse?.bidVersion ?? 0) + 1;

      const bidId = jobBids?.[0]?.bidId;
      const subBidId = subJobBidId ?? '';

      // storeBidData({
      //   jobId,
      //   jobName,
      //   jobType,
      //   jobVendors,
      //   address,
      //   jobServices,
      //   startDate,
      //   stopDate,
      //   mdVendorType,
      //   bidId,
      //   updatedMainBidVersion,
      // });

      if (subJobBidId) {
        storeSubBidData();
      }
      const result = await handleCustomerApproval({
        isOTJFlow,
        mdVendorType,
        vendorId,
        bidId,
        subBidId,
        updatedMainBidVersion,
        updatedSubBidVersion,
        isCustomerApprovalAvailable,
      });

      await handleNewBidUpdateResult(
        result,
        vendorId,
        bidId,
        subBidId,
        updatedMainBidVersion,
        updatedSubBidVersion,
        modifyQuoteJobDetails
      );

      setIsModifyQuoteLoading(false);
    } catch (e: any) {
      console.error('Error', e);
      setIsModifyQuoteLoading(false);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'jobName',
        header: t('tableHeader:jobName'),
        enableSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        enableColumnFilter: true,
        size: 180,
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ color: theme.palette.primary.dark }}>{renderJobLinkCell(renderedCellValue, row)}</div>
        ),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'location',
        header: t('tableHeader:location'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 180,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'jobStatus',
        header: t('tableHeader:jobStatus'),
        enableSorting: false,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 170,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'createdOn',
        header: t('tableHeader:createdOn'),
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 100,
        filterFn: (row, _columnIds, filterValue) => {
          const formattedValue = moment(row.getValue('createdOn')).format('MM / DD / yyyy');
          return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'intId',
        header: t('tableHeader:workTicketNumber'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'jobBids',
        header: t('tableHeader:finalQuote'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'area',
        header: t('tableHeader:totalArea'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        header: t('tableHeader:actions'),
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderActionsCell(row),
      },
    ],
    [handleClick, handleClose]
  );

  const bidsInProgressColumns = useMemo(() => {
    const jobColumns = deepClone(columns);
    jobColumns.splice(
      5,
      5,
      {
        accessorKey: 'jobStatus',
        header: t('tableHeader:jobStatus'),
        enableSorting: false,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 170,
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'bidCount',
        header: t('tableHeader:bidCount'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 100,
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        header: t('tableHeader:view'),
        enableColumnActions: false,
        enablePinning: false,
        size: 70,
        Cell: ({ row }: any) => renderBidsInProgressActionsCell(row),
      }
    );
    jobColumns.splice(2, 1);
    return jobColumns;
  }, [columns]);

  const myCustomSortingFn = (): void => {
    setSortBy('DESC');
  };

  const handleSort = async (sortingObj: any): Promise<void> => {
    setSorting(sortingObj);
    try {
      if (sortingObj.length) {
        setNewSortOrder(sortingObj[0].id);
        setNewSort(sortingObj[0].desc);
        if (tabSelected === 0) {
          await getJobsData(currentPage, sortingObj[0].id, sortingObj[0].desc, columnFilters);
        } else if (tabSelected === 1) {
          await getBidsInProgressData(currentPage, sortingObj[0].id, sortingObj[0].desc, columnFilters);
        }
      } else {
        if (tabSelected === 0) {
          await getJobsData(currentPage);
        } else if (tabSelected === 1) {
          await getBidsInProgressData(currentPage);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const filterJobs = (filtersObj: any): void => {
    setColumnFilters(filtersObj);
    if (filtersObj?.length) {
      if (tabSelected === 0) {
        getJobsData(currentPage, newSortOrder, newSort, filtersObj);
      } else if (tabSelected === 1) {
        getBidsInProgressData(currentPage, newSortOrder, newSort, filtersObj);
      }
    } else {
      getJobsData(currentPage, newSortOrder, newSort, []);
    }
  };

  useEffect(() => {
    if (location?.state?.jobName) {
      setSnackbarMessage(`${location?.state?.jobName} Job successfully created.`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
    if (tabSelected === 1) {
      getBidsInProgressData();
    } else {
      getJobsData();
    }
  }, [tabSelected]);

  useEffect(() => {
    setTabSelected(initialTabIndex);
  }, [location.pathname]);

  useEffect(() => {
    handleChange(null, currentPage);
  }, [selfPerformedJobs]);
  useEffect(() => {
    handleChange(null, currentPage);
  }, [subContractedJobs]);
  useEffect(() => {
    setCurrentPage(1);
  }, [tabSelected]);

  useEffect(() => {
    localStorage.removeItem(AsyncStorageKeys.viewJob);
    localStorage.removeItem(AsyncStorageKeys.currentJob);
    localStorage.removeItem(AsyncStorageKeys.uploadQuote);
    removeJobLocalData();
  }, []);

  useEffect(() => {
    if (!isDistrictManager) {
      const jobsTab = [
        {
          id: 2,
          label: t('vendor:ongoingJobs'),
          route: 'ongoing',
        },
      ];
      window.history.pushState(null, 'Peazy', `/vendor-jobs/ongoing`);
      setModifiedJobsTab(jobsTab);
      setTabSelected(0); // Ensure tabSelected is reset when tabs are modified
    } else {
      setModifiedJobsTab(jobsTab);
      setTabSelected(initialTabIndex === -1 ? 0 : initialTabIndex);
    }
  }, [isDistrictManager, location]);

  return (
    <>
      <PageTitle title={t('dashboard:jobs')} addOTJ={true} showButton={isDistrictManager} />
      <CustomerContainer>
        <TabSection>
          <TabsWrapper>
            <Tabs value={tabSelected} onChange={handleChangeTabs} aria-label="basic tabs">
              {modifiedJobsTab.map((tab, index) => (
                <TabTitle label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          </TabsWrapper>

          {modifiedJobsTab.map((tab, index) => {
            return (
              <TabPanel value={tabSelected} index={index}>
                <>
                  {tab.id === 1 ? (
                    <DataGrid
                      columns={bidsInProgressColumns}
                      data={bidsInProgressJobs}
                      enableRowSelect={false}
                      enableColumnPinning={false}
                      sortingObj={sorting}
                      filter={filterJobs}
                      loader={isLoading}
                      sort={handleSort}
                      sortingFns={{
                        myCustomSortingFn: myCustomSortingFn,
                      }}
                      errorMessageTitle={t('noData:noDataYet')}
                      errorMessageDescription={t('noData:comeBackLater')}
                    />
                  ) : null}
                  {tab.id === 0 ? (
                    <DataGrid
                      columns={columns}
                      data={jobs}
                      enableRowSelect={false}
                      enableColumnPinning={false}
                      loader={isLoading}
                      sortingObj={sorting}
                      filter={filterJobs}
                      sort={handleSort}
                      sortingFns={{
                        myCustomSortingFn: myCustomSortingFn,
                      }}
                      errorMessageTitle={t('noData:noDataYet')}
                      errorMessageDescription={t('noData:comeBackLater')}
                    />
                  ) : null}
                  {tab.id === 2 ? <OngoingJobs /> : null}
                  {!isLoading && totalCount > 0 && (tab.id === 0 || tab.id === 1) ? (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                        <StyledPagination
                          count={Math.ceil(totalCount / itemsPerPage)}
                          page={currentPage}
                          onChange={(event, value) => {
                            if (tab.id === 1) {
                              getBidsInProgressData(value, newSortOrder, newSort, columnFilters);
                            } else {
                              getJobsData(value, newSortOrder, newSort, columnFilters);
                            }
                            handleChange(event, value);
                          }}
                          color="primary"
                        />
                      </Stack>
                      <Typography>{`Total items: ${totalCount}`}</Typography>
                    </Box>
                  ) : null}
                </>
              </TabPanel>
            );
          })}
        </TabSection>
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
        <ConfirmPopup
          open={isCompleteJobPopupOpen}
          handleClose={handleCompletionJobPopup}
          handleOnCloseJob={handleOnCompleteJob}
          isCompleteJobLoading={isCompleteJobLoading}
        />
        <ModifyPopUp
          open={isModifyQuotePopupOpen}
          handleClose={handleModifyQuotePopup}
          handleOnCloseJob={resubmitHandler}
          loading={isModifyQuoteLoading}
          heading={t('assignJob:modifyQuote')}
          text={t('assignJob:confirmModifyQuote')}
        />
        <ModifyPopUp
          open={isBidRevisionPopupOpen}
          handleClose={handleCloseBidRevision}
          handleOnCloseJob={handleBidRevision}
          loading={isBidRevisionLoading}
          heading={t('assignJob:bidRevision')}
          text={t('assignJob:confirmBidRevision')}
        />
        <DeleteJobs
          isApproveWindowOpen={dialogueBoxOpen}
          handleCloseApproveWindow={() => setDialogueBoxOpen(false)}
          deleteJobId={deleteJobId}
          deleteJobName={deleteJobName}
          fetchJobsView={() => {}}
          fetchData={tabSelected === 0 ? getJobsData : getBidsInProgressData}
          tabSelected={tabSelected}></DeleteJobs>
      </CustomerContainer>
    </>
  );
};

export default MarketplacePage;
